/* You can add global styles to this file, and also import other style files */

div.search {
    padding-left: 20px;
    max-width: 97%;
}

th {
    background: #ed1c24 !important;
    color: #fff !important;
}

.mat-header-cell {
    background: #ed1c24 !important;
    color: #fff !important;
    font-weight: 600 !important;
}

.submitButton {
    background: #ed1c24 !important;
    color: #fff !important;
}

.cancelButton {
    background: #d6d5d5 !important;
    color: #000 !important;
}

.mat-header-cell,
.mat-cell,
.mat-footer-cell {
    color: #000;
    font-weight: 400;
}

.mat-header-cell:first-of-type,
.mat-cell:first-of-type,
.mat-footer-cell:first-of-type {
    color: #000;
    font-weight: 400;
}

mat-header-cell {
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 13px;
}


mat-icon.close:hover,
mat-icon.print {
    cursor: pointer;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mt-3 {
    margin-top: 3rem !important;
}

.mt-2 {
    margin-top: 2rem !important;
}

.mt-4 {
    margin-top: 100px !important;
}

.addr {
    font-size: 12px;
    font-style: normal !important;
    text-align: left;
    font-weight: 500;
}

.mat-form-field-infix {
    padding: 0.5em 0 1em 0 !important;
    font-size: 15px !important;
}

.selectLang>div.mat-form-field-wrapper {
    margin: 0px !important;
    padding-bottom: 0px !important;
}

.selectLang>.mat-form-field-infix {
    padding: 0.3em 0 0.4em 0 !important;
    font-size: 15px !important;
}

.mat-card-title {
    padding: 15px 0px 0px 21px !important;
}

.mat-card-subtitle {
    padding: 7px 11px 0px 13px !important;
}

.rejectButton {
    color: rgb(255 255 255) !important;
    background-color: #808285 !important;
}

.tableCls {
    background: #fff !important;
    color: #000 !important;
    border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray;
    text-transform: uppercase;
}

.tableCls1 {
    background: #fff !important;
    color: #000 !important;
    font-size: 12px;

}

.tableClsx {
    background: #fff !important;
    color: #000 !important;
    border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray;
    font-size: 11px;

}

.mat-form-field-label {

    color: #000 !important;

}

.example-element-row td {
    border-bottom-width: 1px !important;
}

/* .addr1{
    width:100px;
} */
.swal2-title {
    font-size: 1.2em !important;
}

.mat-tab-body-content {
    overflow: hidden !important;
}

/* .page-wrapper .page-content {

    padding: 8px !important;

    background: #c6bfbf !important;

} */
.mat-sort-header-arrow {
    color: #fff !important;
}

.ct-label {
    fill: #fafafa;
    color: rgba(0, 0, 0, 0.4);
    font-size: 0.65rem;
    line-height: 1;
}

.ct-series-a .ct-slice-pie,
.ct-series-a .ct-slice-donut-solid,
.ct-series-a .ct-area {
    fill: #0dc6df;
}

.ct-series-b .ct-slice-pie,
.ct-series-b .ct-slice-donut-solid,
.ct-series-b .ct-area {
    fill: #3f51b5;
}

ct-series-c .ct-slice-pie,
.ct-series-c .ct-slice-donut-solid,
.ct-series-c .ct-area {
    fill: #3df4a3;
}

.mat-table {
    /* font-family: Poppins, sans-serif; */
    width: 100%;
}

.float-right {
    text-align: center !important;
}

.poInfoCls {
    cursor: pointer;
    text-decoration: underline;
    color: #0088CC
}

mat-icon.visibility,
mat-icon.edit,
mat-icon.block,
mat-icon.expand {
    color: rgb(136, 134, 134);
}

mat-icon.visibility,
mat-icon.edit,
mat-icon.block {
    font-size: larger;
    margin-left: 6px;
}

mat-icon.visibility:hover,
mat-icon.edit:hover,
mat-icon.block:hover,
mat-icon.expand:hover {
    cursor: pointer;
    color: red;
}




.addUserParent {
    display: flex;
    justify-content: end;
    padding-right: 0px !important;
}

.pull-left {
    display: flex;
    justify-content: start;
}

.center {
    display: flex;
    justify-content: center;
}

.pull-right {
    display: flex;
    justify-content: end;
}

.margin-bottom {
    margin-bottom: 10px;
}

mat-icon.close1 {
    padding-right: 35px;
}

mat-icon.close1:hover,
mat-icon.close2:hover {
    cursor: pointer;
}

form.form-style {
    padding-left: 5px;
}

.submitButton {
    background: #ed1c24;
    color: #fff;
}

.actions-align {
    padding-left: 18px;
}


.center-form {
    display: flex;
    justify-content: center;
}

.pODetails-logo img {
    height: 100px;
    width: 200px;

}

@media (max-width: 320px) {
    /* button.submitButton.addUser{
        position:fixed;
        font-size: small;
        right: 30px;
        top: 236px;
        z-index: 1;
    } */

    .modal-buttons {
        display: flex;
        justify-content: center;
        margin-bottom: 5px;
    }

    mat-cell:last-of-type,
    mat-header-cell:last-of-type,
    mat-footer-cell:last-of-type {
        padding-right: 0px !important;
    }

    .f-start,
    .f-middle,
    .f-end {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    .mat-table .mat-cell.editShortText-w {
        height: max-content !important;
    }

}

@media (min-width: 320px) {
    button.submitButton.addUser {
        height: 35px;
        /* top: 235px; */
        z-index: 1;
        right: 15px;
        /* position: fixed;*/
    }

    button.submitButton.addConfig {
        position: fixed;
        font-size: small;
        right: 40px;
        top: 250px;
    }

    .modal-buttons {
        display: flex;
        justify-content: center;
        margin-bottom: 5px;
    }

    mat-cell:last-of-type,
    mat-header-cell:last-of-type,
    mat-footer-cell:last-of-type {
        padding-right: 0px !important;
    }

    .f-start,
    .f-middle,
    .f-end {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    .mat-table .mat-cell.editShortText-w {
        /* min-height: 60px; */
        height: max-content !important;
    }

    .poinfovendcode {
        display: flex !important;
        justify-content: start;
        align-items: start;
    }


}

@media (max-width: 320px) {
    div.pull-left.title {
        font-size: large !important;
    }

    .buttonGroup {
        display: flex;
        justify-content: center;
        margin-right: 20px !important;
    }

    button.leftBtn {
        margin-right: 10px !important;
    }


}

@media (min-width: 375px) {
    div.pull-left.title {
        font-size: large !important;
        padding-left: 0px;
    }

    button.submitButton.addConfig {
        position: fixed;
        right: 30px;
        top: 155px
    }

    .buttonGroup {
        display: flex;
        justify-content: center;
        margin-right: 20px !important;
    }

    button.leftBtn {
        margin-right: 10px !important;
    }

}

@media (min-width: 500px) {}

@media (max-width:404px) {
    .mat-table .mat-cell.editCategory-w {
        /* min-height: 60px; */
        height: max-content !important;
    }

    .mat-table .mat-cell.editShortText-w {
        /* min-height: 80px; */
        height: max-content !important;
    }
}

/* @media (min-width:768px){
    button.submitButton.addConfig{
        position: fixed;
        right: 3%;
    }*/

@media (min-width: 600px) {

    .padding-right {
        padding-right: 10px;
    }

    .padding-left {
        padding-left: 10px;
    }

    .modal-buttons {
        display: flex;
        justify-content: end;
        margin-bottom: 5px;
    }

    .f-start {
        display: flex;
        justify-content: start;
        align-items: flex-start;
    }

    .f-middle {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .f-end {
        display: flex;
        justify-content: start;
        align-items: flex-end;
    }

    mat-cell:last-of-type,
    mat-header-cell:last-of-type,
    mat-footer-cell:last-of-type {
        padding-right: 10px !important;
    }
}

@media (min-width: 769px) {

    div.pull-left.title {
        font-size: 20px !important;
    }

    button.submitButton.addUser {
        /* position: static;
        height: 35px; */
        margin-top: 22px;
    }

    .pODetails-logo img {
        height: 100px;
        width: 150px;

    }

    .f-start img {
        width: 150px;
        height: 50px;
    }


    /* mat-header-cell.actions-w, mat-cell.actions-w{
        max-width: 100px;
        display: flex;
        justify-content: center;
    } */

    mat-header-cell.property-w,
    mat-cell.property-w {
        max-width: 500px;
    }

    mat-header-cell.response-w,
    mat-cell.response-w {
        max-width: 200px;
    }

    /* button.submitButton.addConfig{
        position: fixed;
        right: 3%;
    } */
    .pad {
        margin-top: 20px;
    }

    .deliveryNoteDetails {
        margin-top: 0px;
        /* margin-top: -20px; */
        padding-left: 0px;
    }

    .buttonGroup {
        display: flex;
        justify-content: end;
        margin-right: 20px !important;
    }

    button.leftBtn {
        margin-right: 10px !important;
    }

    .delete-row-parent {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
    }

    .page-group {
        display: flex;
        justify-content: end;

    }
}

@media (min-width:800px) {
    /* button.submitButton.addUser{
        position: relative;
        right: -40px;
        top: 16px
    }   */
}

/*
@media screen and (max-width: 851px),
       screen and (max-height: 438px){
     .mat-sort-header-content{
         font-size: 10px;
         font-weight: 500;
     }
     mat-header-cell.actions-w{
         font-size: 10px;
         font-weight: 500;
     }
     .mat-cell{
         font-size: 12px !important;
     }

     mat-header-cell.email-w,
     mat-cell.email-w{
         min-width: 170px;
     }

} */

@media (min-width:992px) {
    .pODetails-logo img {
        height: 100px;
        width: 200px;

    }

    /* button.submitButton.addUser{
        position: relative;
        right: -137px;
        top: 16px
    }  */
    mat-cell.email-w {
        min-width: 170px;
    }

    mat-header-cell.email-w {
        min-width: 170px;
    }

    mat-header-cell.description-w,
    mat-cell.description-w {
        min-width: 450px;
    }

    mat-header-cell.role-w,
    mat-cell.role-w {
        max-width: 200px;
    }
}

@media (min-width:1024px) {

    /* button.submitButton.addUser{
        position: fixed;
        right: 45px;
        top: 155px
    } */
    button.submitButton.addConfig {
        position: fixed;
        right: 31px;
        top: 155px
    }

    mat-cell.email-w {
        min-width: 225px;
    }

    mat-header-cell.email-w {
        min-width: 225px;
    }

    mat-cell.contact-w {
        min-width: 100px;
    }

    mat-header-cell.contact-w {
        min-width: 100px;
    }
}

@media (min-width:1280px) {
    /* button.submitButton.addUser{
        position: fixed;
        right: 0px;
        top: 155px
    } */
}

mat-row.expandRow {
    align-items: unset;
    min-height: auto;
}

/*Table view for screen size less than 768Px  Starts--->*/
@media (max-width: 768px) {

    /* mat-cell.actions-w {
        padding: 0px 0px 0px 0px !important;
    } */

    .mat-table .mat-header-cell {
        display: none;
    }

    .mat-table {
        border: 0;
        vertical-align: middle
    }

    .mat-table caption {
        font-size: 1em;
    }

    .mat-table .mat-row {
        /* border-bottom: 5px solid #ddd; */
        display: block;
    }

    .mat-table .mat-cell {
        width: auto;
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 1em;
        text-align: right;
        margin-right: 20px;
        height: 30px;
        margin-bottom: 4%;
    }

    .mat-table .mat-cell:before {
        content: attr(data-label);
        float: left;
        margin-left: 15px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: .85em;
    }

    .mat-table.deliveryNoteDetailsTable .mat-cell:before {
        margin-left: 5px;
    }

    .mat-table .mat-cell:last-child {
        border-bottom: 0;
    }

    .mat-table .mat-cell:first-child {
        margin-top: 4%;
    }

    .mat-table .mat-cell:first-of-type {
        padding-left: 0px;
    }

    .mat-table .mat-cell.editShortText-w {
        /* height: 120px; */
        height: max-content !important;
    }

    /* .mat-table .mat-cell.editDelQty-w{
        min-height: 70px;
    } */
    .mat-table .mat-cell.editStorageLocation-w {
        /* min-height: 50px; */
        height: max-content !important;
    }

    mat-cell input.delQty {
        position: relative;
        left: 208px;
        top: -33px;
        background: transparent;
        font-size: unset;
    }

    mat-icon.delete {
        position: relative;
        right: 0px;
        top: 15px
    }

    .overflow1 {
        overflow-x: scroll;
        width: 90vw;
    }

    mat-row.expandRow {
        overflow-y: scroll;
    }

    .appName,
    .userName {
        display: none;
    }

    /* .wht-group{
        display: flex;
        flex-direction: column;
        background-color: red;
    } */

    /*Info Pages ---------------------------->*/
    h6.supportDocHeading {
        display: flex;
        justify-content: center;
    }

    /*Info Pages <----------------------------*/
}

th.mat-header-cell {
    text-align: left;
    text-transform: uppercase;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
    color: rgba(0, 0, 0, 0.5) !important;
}

.addr1 {
    width: 100px;
    font-weight: 600;
}

.page-group {
    display: flex;
    justify-content: end;

}

/*Table view for screen size less than 768Px <-----Ends*/

/*Basic HTML Table Responsive Styling ---------->Starts*/

/* ul li{
    list-style: none;
    
}

ul{
    padding: 0px !important;
    margin: 0px !important;
}

th,
td {
    border: 1px solid #ddd;
    padding: 10px 10px;
}

th {
    background-color: #f1f1f1;
}

input {
    margin: 0px;
    padding: 4px 0px;
    text-align: center;
    border: 1px solid #ddd;
    border-collapse: collapse;
    border-color: #ddd;
    outline: none;
    width: 20%;
}

.table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}
  @media(max-width: 600px) {

    
  
      .table thead {
          display: none !important;
      }

      .table tbody,
      tr,
      td {
          display: block;
          width: 100%;
          float: right;
      }

      .table tr {
          margin-bottom: 15px;
      }

      .table td {
          text-align: right;
          padding-left: 40%;
      }

      .table tr:nth-child(:first-child) {
          text-align: center;
      }

      .table td::before {
          content: attr(data-name);
          position: absolute;
          left: 10px;
          width: 50%;
          text-align: left;
          padding-left: 15px;
          font-size: 0.9rem;
          font-weight: 600;

      }

      .tableRow{
          margin-bottom: 40px !important;
          background-color: #fdfdfd;
      }
  } */



/*Basic HTML Table Responsive Styling <-----------Ends*/


/*Table Styling for Different screen width*/

.overflow {
    overflow-x: scroll;
    width: auto;
}

/* working_Code*/

/* .overflow{
    overflow-x: scroll;
    min-width: 75vw;
}  */

.storageLocation-w {
    min-width: 130px;
}

.editStorageLocation-w {
    min-width: 150px !important;
}

.mat-select-arrow-wrapper {
    width: 100vw;
}

/* .mat-icon.delete{
    margin-left: 30px !important;
} */
.mat-icon.delete:hover {
    color: red;
    cursor: pointer;
}

.mat-icon {
    font-size: large;
}

.mat-icon-parent {
    display: flex;
    justify-content: center;
}

.delete-row {
    font-size: x-large !important;
}

mat-table.poInfoTable {
    width: 100% !important;
}

mat-table.dnInfoTable {
    width: 100% !important;
}

mat-table.InvoiceDetailsTable {
    width: 100% !important;
}

/* mat-table.responseTable{
    min-width:77vw !important;
    width: inherit !important;
} */


/* mat-table.roleManagementTable{
    min-width:75vw !important;
    width: inherit !important;
} */

.weighTicketNo-w {
    min-width: 130px !important;
}

.currency-w {
    min-width: 95px;
    max-width: 95px;
}

.status1-w {
    max-width: 90px !important;
}

.actions-w {
    min-width: 110px !important;
    max-width: 110px !important;
}


.paymentTerms-w {
    min-width: 130px !important;
    max-width: 200px !important;
}

.paymentMethod-w {
    justify-content: space-around;
    min-width: 130px !important;
    max-width: 150px !important;
}

.whtType-w {
    min-width: 100px !important;
    max-width: 110px !important;
}

.whtCode-w {
    min-width: 100px !important;
    max-width: 110px !important;
}

.whtAmount-w {
    min-width: 120px !important;
    max-width: 135px !important;
}

.invoiceNoVP-w {
    min-width: 142px !important;
    max-width: 150px !important;
}

.poNo-w {
    min-width: 100px !important;
    max-width: 200px !important;
}

.sapUser-w {
    min-width: 100px !important;
    max-width: 120px !important;
}

.deliveryNoteNo-w {
    min-width: 150px !important;
    max-width: 300px !important;
}

.invNo-w {
    min-width: 150px !important;
}

@media (min-width: 769px) and (max-width: 900px) {
    mat-table.userManagementTable {
        min-width: 152vw !important;
        width: inherit !important;
    }

    mat-table.vendorUserTable {
        min-width: 140vw !important;
        width: inherit !important;

    }

    .poInfoDetail {
        display: flex;
        flex-direction: column;
    }

    .mat-table {
        width: 200% !important;
    }


    mat-table.deliveryNoteDetailsTable {
        width: 222vw !important;
    }

    mat-table.invoiceGoodsTable {
        width: 200vw !important;
    }

    mat-table.invoiceServiceTable {
        width: 200vw !important;
    }


    mat-header-cell:first-of-type,
    mat-footer-cell:first-of-type {
        padding-left: 15px !important;
    }

    mat-cell:first-of-type,
    mat-header-cell:first-of-type,
    mat-footer-cell:first-of-type {
        padding-left: 15px !important;
    }

    .role-w {
        min-width: 80px !important;


    }

    .status-w {
        min-width: 100px !important;
    }


    .shipmentNo-w {
        min-width: 160px !important;
        max-width: 200px !important;
    }

    .poNo-w {
        min-width: 95px;
        max-width: 150px;
    }

    .editPoNo-w {
        max-width: 110px !important;
    }

    .totalAmt-w {
        justify-content: end;
        min-width: 115px;
        max-width: 150px !important;
    }

    .vendorName-w {
        min-width: 140px !important;
        max-width: 300px !important;
        padding-right: 10px;
    }

    .currency-w {
        min-width: 95px;
        max-width: 95px;
    }

    .invoiceDate-w {
        min-width: 105px !important;
        max-width: 105px !important;
    }

    .invoiceType-w {
        min-width: 100px;
        max-width: 100px;
    }

    .totalQty-w {
        min-width: 100px !important;
    }

    .taxType-w {
        min-width: 110px !important;
    }

    .vatAmt-w {
        min-width: 80px !important;
    }


    .editPoItem-w {
        max-width: 80px !important;
    }


    .editMaterialGroup-w {
        max-width: 125px !important;
    }

    .materialNo-w {
        min-width: 100px !important;
    }

    .editMaterialNo-w {
        max-width: 100px !important;
    }

    .shortText-w {
        min-width: 340px !important;
        padding-right: 10px !important;
    }


    .editPoQty-w {
        max-width: 80px !important;
    }


    .editReceivedQty-w {
        max-width: 105px !important;
    }


    .editPendingQty-w {
        max-width: 100px !important;
    }


    .editUnitPrice-w {
        max-width: 100px !important;
    }

    .poAmount-w {
        min-width: 100px;
    }

    .editPoAmount-w {
        max-width: 100px;
    }

    .editDelQty-w {
        max-width: 80px;
    }

    .storageLocation-w {
        min-width: 130px !important;
    }

    .editStorageLocation-w {
        max-width: 150px !important;
    }

    .editDNtotalAmt-w {
        max-width: 115px !important;
    }

    .editCategory-w {
        max-width: 200px !important;
    }

    .editShortText-w {
        min-width: 350px !important;
    }


    mat-icon.expand {
        margin-left: 30px;
    }

    .editDNnoteNo-w {
        max-width: 225px;
    }

    .editDeliveringQty-w {
        max-width: 120px !important;
    }


    .accAssignment-w {
        min-width: 150px;
    }

    .editPOItem-w {
        min-width: 100px;
    }

    .sVItemNo-w {
        min-width: 100px;
    }

    .sVPendingQty-w {
        min-width: 110px;
    }

    .sVQty-w {
        max-width: 70px;
    }

    .sVText-w {
        min-width: 150px;
    }

    .itemCode-w {
        min-width: 150px;
    }

    .editPONo-w {
        min-width: 160px;
    }

    mat-table.responseTable {
        min-width: 58vw !important;
        width: inherit !important;
    }

    mat-header-cell.response-w,
    mat-cell.response-w {
        min-width: 130px !important;
        max-width: 150px;
    }

    mat-header-cell.message-w,
    mat-cell.message-w {
        min-width: 250px;
        max-width: 570px;
    }

    mat-header-cell.action-w,
    mat-cell.action-w {
        max-width: 100px;
    }

    .first-name-w,
    .last-name-w {
        min-width: 110px !important;
        max-width: 140px !important;

    }

    .role-w {
        min-width: 105px !important;
        max-width: 130px !important;
    }

    .contact-w {
        min-width: 105px !important;
        max-width: 140px !important;
    }

    .email-w {
        min-width: 200px !important;
        max-width: 400px !important;
        word-break: break-word;
    }

    /* .status1-w{
        min-width: 75px !important;
        max-width: 110px !important;
    } */
    /* .actions-w{
        min-width: 75px !important;
        max-width: 100px !important;
    } */
    mat-table.roleManagementTable {
        min-width: 58vw !important;
        width: inherit !important;
    }

    .role-rm-w {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .description-rm-w {
        min-width: 150px !important;
        max-width: 600px !important;
    }

    mat-table.ConfigurationTable {
        min-width: 58vw !important;
        width: inherit !important;
    }

    .propKey {
        min-width: 120px !important;
        max-width: 300px !important;
    }

    .propValue {
        min-width: 270px !important;
        max-width: 500px !important;
    }

    mat-table.AuditTable {
        min-width: 56vw !important;
        width: inherit !important;
    }

    .date-w {
        min-width: 90px !important;
        max-width: 110px !important;
    }

    mat-table.vendorListTable {
        min-width: 125vw !important;
        width: inherit !important;
    }

    .status1-user-w {
        min-width: 85px !important;
        max-width: 150px !important;
    }

    .status2-user-w {
        min-width: 85px !important;
        max-width: 100px !important;
    }

    .active-w {
        min-width: 80px !important;
        max-width: 180px !important;
    }

    .city-w {
        min-width: 150px;
        max-width: 650px;
    }

    .country-w {
        min-width: 80px !important;
        max-width: 150px !important;
    }

    .currency-w {
        min-width: 80px !important;
        max-width: 150px !important;
    }

    .vendorGroup-w {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .companyCode-w {
        justify-content: space-around;
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .vendorCode-w {
        min-width: 135px !important;
        max-width: 200px !important;
    }

    .actions1-w {
        max-width: 115px !important;
    }

    mat-table.deliveryNotePendingTable {
        min-width: 175vw !important;
        width: inherit !important;
    }

    mat-table.deliveryNoteHistoryTable {
        min-width: 215vw !important;
        width: inherit !important;
    }

    .materialDocNum-w {
        min-width: 150px !important;
        max-width: 250px !important;
    }

    .materialDocYear-w {
        min-width: 150px !important;
        max-width: 200px !important;
    }

    .sapResponse-w {
        min-width: 150px !important;
        max-width: 300px !important;
    }

    .invoiceNo-w {
        min-width: 150px !important;
        max-width: 180px !important;
    }

    .shipmentNo-w {
        min-width: 160px !important;
        max-width: 200px !important;
    }

    .deliveryRefNo-w {
        min-width: 180px !important;
        max-width: 300px !important;
    }

    .deliveryNoteDate-w {
        min-width: 165px !important;
        max-width: 165px !important;
    }

    .quantity-w {
        min-width: 100px !important;
        max-width: 150px !important;
    }


    .totalAmt-w {
        padding-right: 30px;
        justify-content: end;
        min-width: 120px !important;
        max-width: 180px !important;
    }

    .statusWide-w {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .createdBy-w {
        min-width: 140px !important;
        max-width: 250px !important;
    }


    mat-table.purchaseOrderTable {
        min-width: 243vw !important;
        width: inherit !important;
    }

    mat-table.purchaseOrderHistoryTable {
        min-width: 242vw !important;
        width: inherit !important;
    }

    .companyName-w {
        min-width: 135px !important;
        max-width: 200px !important;
    }

    .poNo-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .poItem-w {
        min-width: 95px !important;
        max-width: 200px !important;
    }

    .plantCode-w {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .plantName-w {
        min-width: 135px !important;
        max-width: 300px !important;
    }

    .materialGroup-w {
        min-width: 135px !important;
        max-width: 200px !important;
    }

    .shortText1-w {
        min-width: 160px !important;
        max-width: 600px !important;
    }

    .poQty-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .receivedQty-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .pendingQty-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .unitPrice-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .itemAmt-w {
        min-width: 135px !important;
        max-width: 250px !important;
    }

    .invoicedAmt-w {
        min-width: 135px !important;
        max-width: 250px !important;
    }

    .totalAmt1-w {
        min-width: 135px !important;
        max-width: 250px !important;
    }

    mat-table.invoiePendingListTable {
        min-width: 149vw !important;
        width: inherit !important;
    }

    mat-table.invoieHistoryListTable {
        min-width: 190vw !important;
        width: inherit !important;
    }

    .invoiceNo-w {
        min-width: 150px !important;
        max-width: 180px !important;
    }

    .invoiceDate-w {
        min-width: 105px !important;
        max-width: 150px !important;
    }

    .invoiceType-w {
        min-width: 100px;
        max-width: 200px;
    }

    .totalQty-w {
        min-width: 100px;
        max-width: 200px;
    }

    .taxType-w {
        min-width: 100px;
        max-width: 200px;
    }

    .vatAmt-w {
        min-width: 100px;
        max-width: 200px;
    }

    .status-w {
        min-width: 100px;
        max-width: 150px;
    }

    mat-table.weighBridgeTable {
        min-width: 234vw !important;
        width: inherit !important;
    }

    mat-table.weighBridgeTableUp {
        min-width: 295vw !important;
        width: inherit !important;
    }

    .weighTicketNo-wb {
        min-width: 195px !important;
        max-width: 250px !important;
    }

    .truckNo-wb {
        min-width: 175px !important;
        max-width: 250px !important;
    }

    .shipmentNo-wb {
        min-width: 160px !important;
        max-width: 250px !important;
    }

    .weightType-wb {
        min-width: 140px !important;
        max-width: 250px !important;
    }

    .poNumber-wb {
        min-width: 200px !important;
        max-width: 300px !important;
    }

    .itemNo-wb {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .docDate-wb {
        min-width: 150px !important;
        max-width: 200px !important;
    }

    .material-wb {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .materialDesc-wb {
        min-width: 200px !important;
        max-width: 400px !important;
    }

    .weightUnit-wb {
        min-width: 80px !important;
        max-width: 125px !important;
    }

    .weightInQty-wb {
        min-width: 170px !important;
        max-width: 250px !important;
    }

    .vendorNetWeight-wb {
        min-width: 175px !important;
        max-width: 250px !important;
    }

    .weightOutQty-wb {
        min-width: 180px !important;
        max-width: 350px !important;
    }

    .materialDoc-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .materialDocYear-wb {
        min-width: 170px !important;
        max-width: 250px !important;
    }

    .supplyPlant-wb {
        min-width: 150px !important;
        max-width: 280px !important;
    }

    .supplyPtorage-wb {
        min-width: 160px !important;
        max-width: 300px !important;
    }

    .recvpoPlant-wb {
        min-width: 180px !important;
        max-width: 300px !important;
    }

    .recvpoStorage-wb {
        min-width: 195px !important;
        max-width: 300px !important;
    }

    .destinationPlant-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .destinationStorage-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .requiredQty-wb {
        min-width: 170px !important;
        max-width: 350px !important;
    }

    .buom-wb {
        min-width: 165px !important;
        max-width: 200px !important;
    }

    .vendor-wb {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .name1-wb {
        min-width: 130px !important;
        max-width: 300px !important;
    }

}

@media (min-width: 901px) and (max-width: 999px) {
    mat-table.userManagementTable {
        min-width: 125vw !important;
        width: inherit !important;
    }

    mat-table.vendorUserTable {
        min-width: 118vw !important;
        width: inherit !important;

    }

    .mat-header-cell,
    .mat-cell {
        justify-content: start;
    }

    .mat-table {
        width: 200% !important;
    }


    mat-table.deliveryNoteDetailsTable {
        width: 167vw !important;
    }


    mat-table.invoiceGoodsTable {
        width: 175vw !important;
    }

    mat-table.invoiceServiceTable {
        width: 165vw !important;
    }

    mat-header-cell:first-of-type,
    mat-footer-cell:first-of-type {
        padding-left: 15px !important;
    }

    mat-cell:first-of-type,
    mat-header-cell:first-of-type,
    mat-footer-cell:first-of-type {
        padding-left: 15px !important;
    }


    .mat-header-cell.contact-w,
    .mat-cell.contact-w {
        min-width: 115px !important;
    }

    .editPoNo-w {
        min-width: 110px !important;
    }

    .totalAmt-w {
        padding-right: 30px;
        justify-content: end;
        min-width: 115px;
        max-width: 150px;
    }


    .vendorName-w {
        min-width: 140px !important;
        max-width: 300px !important;
        padding-right: 10px;
    }

    .currency-w {
        min-width: 95px;
        max-width: 95px !important;
    }


    .editPoItem-w {
        min-width: 80px !important;
    }

    .editMaterialGroup-w {
        min-width: 125px !important;
    }

    .materialNo-w {
        min-width: 100px !important;
    }

    .editMaterialNo-w {
        min-width: 100px !important;
    }

    .shortText-w {
        min-width: 340px !important;
        padding-right: 10px !important;
    }

    .editShortText-w {
        min-width: 345px !important;
    }

    .editPoQty-w {
        min-width: 80px !important;
    }


    .editReceivedQty-w {
        min-width: 105px !important;
    }

    .editPendingQty-w {
        min-width: 100px !important;
    }


    .editUnitPrice-w {
        min-width: 100px !important;
    }

    .poAmount-w {
        min-width: 100px;
    }

    .editPoAmount-w {
        min-width: 100px;
    }


    .editDelQty-w {
        min-width: 80px;
    }

    .editDNtotalAmt-w {
        min-width: 115px !important;
    }

    mat-icon.expand {
        margin-left: 30px;
    }


    .editDNnoteNo-w {
        min-width: 225px;
    }

    .editDeliveringQty-w {
        max-width: 120px !important;
    }

    .accAssignment-w {
        min-width: 150px;
    }

    .editPOItem-w {
        min-width: 100px;
    }

    .sVItemNo-w {
        min-width: 100px;
    }

    .sVPendingQty-w {
        min-width: 110px;
    }

    .sVQty-w {
        max-width: 70px;
    }

    .sVText-w {
        min-width: 150px;
    }

    .itemCode-w {
        min-width: 150px;
    }

    .editPONo-w {
        min-width: 160px;
    }

    .mat-table.font-info .mat-header-cell,
    .mat-table.font-info .mat-cell {
        font-size: 7px !important;
    }

    .po-item-po {
        max-width: 50px !important;
    }

    .material-po {
        max-width: 70px !important;
    }

    .shortText-po {
        min-width: 150px !important;
    }

    .invoicedAmt-po {
        max-width: 90px !important;
    }

    .itemAmt-po {
        max-width: 90px !important;
    }

    .ItemQty-po {
        max-width: 70px !important;
    }

    .recQty-po {
        max-width: 80px !important;
    }

    .penQty-po {
        max-width: 80px !important;
    }

    .unitPrice-po {
        max-width: 70px !important;
    }

    .penAmt-po {
        max-width: 90px !important;
    }

    .po-number-dn {
        max-width: 70px !important;
    }

    .po-item-dn {
        max-width: 60px !important;
    }

    .material-dn {
        max-width: 70px !important;
    }

    .materialNo-dn {
        max-width: 70px !important;
    }

    .shortText-dn {
        min-width: 120px !important;
    }

    .deliveringQty-dn {
        max-width: 70px !important;
    }

    .PoAmt-dn {
        max-width: 80px !important;
    }

    .PoQty-dn {
        max-width: 70px !important;
    }

    .recQty-dn {
        max-width: 70px !important;
    }

    .penQty-dn {
        max-width: 70px !important;
    }

    .unitPrice-dn {
        max-width: 70px !important;
    }

    .totalAmt-dn {
        max-width: 80px !important;
    }

    .dnNo-In {
        min-width: 120px !important;
    }

    .material-In {
        max-width: 80px !important;
    }

    .shortText-In {
        min-width: 120px !important;
    }

    .deliveringQty-In {
        max-width: 80px !important;
    }

    .PoAmt-In {
        max-width: 80px !important;
    }

    .PoQty-In {
        max-width: 70px !important;
    }

    .recQty-In {
        max-width: 70px !important;
    }

    .penQty-In {
        max-width: 70px !important;
    }

    .unitPrice-In {
        max-width: 70px !important;
    }

    .totalAmt-In {
        max-width: 80px !important;
    }

    /* .actions-wide{min-width: 70px !important;
        padding-right: 45px !important;
    } */

    /* mat-icon.visibility, mat-icon.edit, mat-icon.block{
       font-size: xx-small;
    } */

    /* .first-name-w,
    .last-name-w,
    .email-w,
    .contact-w,
    .contact-w,
    .role-w,
    .status-w,
    .actions-w{
        font-size: 10px !important;
        font-weight: 500 !important;
    } */

    mat-table.responseTable {
        min-width: 64vw !important;
        width: inherit !important;
    }

    mat-header-cell.response-w,
    mat-cell.response-w {
        min-width: 120px !important;
        max-width: 150px;
    }

    mat-header-cell.message-w,
    mat-cell.message-w {
        min-width: 350px !important;
        max-width: 680px;
    }

    mat-header-cell.action-w,
    mat-cell.action-w {
        max-width: 100px;
    }

    .first-name-w,
    .last-name-w {
        min-width: 110px !important;
        max-width: 140px !important;

    }

    .role-w {
        min-width: 90px !important;
        max-width: 130px !important;
    }

    .contact-w {
        min-width: 90px !important;
        max-width: 140px !important;
    }

    .email-w {
        min-width: 200px !important;
        max-width: 400px !important;
        word-break: break-word;
    }

    /* .status1-w{
        min-width: 75px !important;
        max-width: 110px !important;
    } */
    /* .actions-w{
        min-width: 75px !important;
        max-width: 100px !important;
    } */
    mat-table.roleManagementTable {
        min-width: 64vw !important;
        width: inherit !important;
    }

    .role-rm-w {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .description-rm-w {
        min-width: 200px !important;
        max-width: 600px !important;
    }

    mat-table.ConfigurationTable {
        min-width: 64vw !important;
        width: inherit !important;
    }

    .propKey {
        min-width: 120px !important;
        max-width: 350px !important;
    }

    .propValue {
        min-width: 300px !important;
        max-width: 600px !important;
    }

    mat-table.AuditTable {
        min-width: 60vw !important;
        width: inherit !important;
    }

    .date-w {
        min-width: 90px !important;
        max-width: 110px !important;
    }

    mat-table.vendorListTable {
        min-width: 105vw !important;
        width: inherit !important;
    }

    .status1-user-w {
        min-width: 85px !important;
        max-width: 150px !important;
    }

    .status2-user-w {
        min-width: 85px !important;
        max-width: 100px !important;
    }

    .active-w {
        min-width: 80px !important;
        max-width: 180px !important;
    }

    .city-w {
        min-width: 150px;
        max-width: 650px;
    }

    .country-w {
        min-width: 80px !important;
        max-width: 150px !important;
    }

    .currency-w {
        min-width: 80px !important;
        max-width: 150px !important;
    }

    .vendorGroup-w {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .companyCode-w {
        justify-content: space-around;
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .vendorCode-w {
        min-width: 135px !important;
        max-width: 200px !important;
    }


    .actions1-w {
        max-width: 115px !important;
    }

    mat-table.deliveryNotePendingTable {
        min-width: 145vw !important;
        width: inherit !important;
    }

    mat-table.deliveryNoteHistoryTable {
        min-width: 190vw !important;
        width: inherit !important;
    }

    .materialDocNum-w {
        min-width: 150px !important;
        max-width: 250px !important;
    }

    .materialDocYear-w {
        min-width: 150px !important;
        max-width: 200px !important;
    }

    .sapResponse-w {
        min-width: 150px !important;
        max-width: 300px !important;
    }

    .shipmentNo-w {
        min-width: 160px !important;
        max-width: 200px !important;
    }

    .deliveryRefNo-w {
        min-width: 180px !important;
        max-width: 300px !important;
    }

    .deliveryNoteDate-w {
        min-width: 165px !important;
        max-width: 165px !important;
    }

    .quantity-w {
        min-width: 100px !important;
        max-width: 150px !important;
    }


    .totalAmt-w {
        padding-right: 30px;
        justify-content: end;
        min-width: 120px !important;
        max-width: 180px !important;
    }

    .statusWide-w {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .createdBy-w {
        min-width: 140px !important;
        max-width: 250px !important;
    }


    mat-table.purchaseOrderTable {
        min-width: 207vw !important;
        width: inherit !important;
    }

    mat-table.purchaseOrderHistoryTable {
        min-width: 207vw !important;
        width: inherit !important;
    }

    .companyName-w {
        min-width: 135px !important;
        max-width: 200px !important;
    }

    .poNo-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .poItem-w {
        min-width: 95px !important;
        max-width: 200px !important;
    }

    .plantCode-w {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .plantName-w {
        min-width: 135px !important;
        max-width: 300px !important;
    }

    .materialGroup-w {
        min-width: 135px !important;
        max-width: 200px !important;
    }

    .shortText1-w {
        min-width: 160px !important;
        max-width: 600px !important;
    }

    .poQty-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .receivedQty-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .pendingQty-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .unitPrice-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .itemAmt-w {
        min-width: 135px !important;
        max-width: 250px !important;
    }

    .invoicedAmt-w {
        min-width: 135px !important;
        max-width: 250px !important;
    }

    .totalAmt1-w {
        min-width: 135px !important;
        max-width: 250px !important;
    }

    mat-table.invoiePendingListTable {
        min-width: 128vw !important;
        width: inherit !important;
    }

    mat-table.invoieHistoryListTable {
        min-width: 152vw !important;
        width: inherit !important;
    }

    .invoiceNo-w {
        min-width: 150px !important;
        max-width: 180px !important;
    }

    .invoiceDate-w {
        min-width: 105px !important;
        max-width: 150px !important;
    }

    .invoiceType-w {
        min-width: 100px;
        max-width: 200px;
    }

    .totalQty-w {
        min-width: 100px;
        max-width: 200px;
    }

    .taxType-w {
        min-width: 100px;
        max-width: 200px;
    }

    .vatAmt-w {
        min-width: 100px;
        max-width: 200px;
    }

    .status-w {
        min-width: 100px;
        max-width: 150px;
    }

    mat-table.weighBridgeTable {
        min-width: 233vw !important;
        width: inherit !important;
    }

    mat-table.weighBridgeTableUp {
        min-width: 252vw !important;
        width: inherit !important;
    }

    .weighTicketNo-wb {
        min-width: 195px !important;
        max-width: 250px !important;
    }

    .truckNo-wb {
        min-width: 175px !important;
        max-width: 250px !important;
    }

    .shipmentNo-wb {
        min-width: 160px !important;
        max-width: 250px !important;
    }

    .weightType-wb {
        min-width: 140px !important;
        max-width: 250px !important;
    }

    .poNumber-wb {
        min-width: 200px !important;
        max-width: 300px !important;
    }

    .itemNo-wb {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .docDate-wb {
        min-width: 150px !important;
        max-width: 200px !important;
    }

    .material-wb {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .materialDesc-wb {
        min-width: 200px !important;
        max-width: 400px !important;
    }

    .weightUnit-wb {
        min-width: 80px !important;
        max-width: 125px !important;
    }

    .weightInQty-wb {
        min-width: 170px !important;
        max-width: 250px !important;
    }

    .vendorNetWeight-wb {
        min-width: 175px !important;
        max-width: 250px !important;
    }

    .weightOutQty-wb {
        min-width: 180px !important;
        max-width: 350px !important;
    }

    .materialDoc-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .materialDocYear-wb {
        min-width: 170px !important;
        max-width: 250px !important;
    }

    .supplyPlant-wb {
        min-width: 150px !important;
        max-width: 280px !important;
    }

    .supplyPtorage-wb {
        min-width: 160px !important;
        max-width: 300px !important;
    }

    .recvpoPlant-wb {
        min-width: 180px !important;
        max-width: 300px !important;
    }

    .recvpoStorage-wb {
        min-width: 195px !important;
        max-width: 300px !important;
    }

    .destinationPlant-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .destinationStorage-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .requiredQty-wb {
        min-width: 170px !important;
        max-width: 350px !important;
    }

    .buom-wb {
        min-width: 165px !important;
        max-width: 200px !important;
    }

    .vendor-wb {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .name1-wb {
        min-width: 130px !important;
        max-width: 300px !important;
    }

}

@media (min-width: 1000px) and (max-width: 1099px) {
    .mat-table {
        width: 150% !important;
    }

    mat-table.userManagementTable {
        min-width: 122vw !important;
        width: inherit !important;
    }

    mat-table.vendorUserTable {
        min-width: 108vw !important;
        width: inherit !important;

    }


    mat-table.deliveryNoteDetailsTable {
        width: 159vw !important;
    }

    mat-table.invoiceGoodsTable {
        width: 150vw !important;
    }

    mat-table.invoiceServiceTable {
        width: 150vw !important;
    }


    mat-header-cell:first-of-type,
    mat-footer-cell:first-of-type {
        padding-left: 20px !important;
    }

    mat-cell:first-of-type,
    mat-header-cell:first-of-type,
    mat-footer-cell:first-of-type {
        padding-left: 20px !important;
    }


    .editPoNo-w {
        min-width: 110px !important;
    }


    .editDNtotalAmt-w {
        min-width: 115px !important;
    }

    .vendorName-w {
        min-width: 140px !important;
        max-width: 300px !important;
        padding-right: 10px;
    }

    .currency-w {
        max-width: 95px !important;
    }

    .poItem-w {
        min-width: 80px !important;
    }

    .editPoItem-w {
        min-width: 80px !important;
    }

    .editMaterialGroup-w {
        min-width: 125px !important;
    }

    .materialNo-w {
        min-width: 100px !important;
    }

    .editMaterialNo-w {
        min-width: 100px !important;
    }

    .shortText-w {
        min-width: 340px !important;
        padding-right: 10px !important;
    }

    .editShortText-w {
        min-width: 345px !important;
    }


    .editPoQty-w {
        min-width: 80px !important;
    }

    .editReceivedQty-w {
        min-width: 105px !important;
    }

    .editPendingQty-w {
        min-width: 100px !important;
    }

    .editUnitPrice-w {
        min-width: 100px !important;
    }

    .invoicedAmt-w {
        min-width: 125px !important;
    }

    .poAmount-w {
        min-width: 100px;
    }

    .editPoAmount-w {
        min-width: 100px;
    }

    .editDelQty-w {
        min-width: 80px;
    }

    .editCategory-w {
        min-width: 150px !important;
    }

    mat-icon.expand {
        margin-left: 30px;
    }

    .editDNnoteNo-w {
        min-width: 230px;
    }

    .editDeliveringQty-w {
        min-width: 110px !important;
    }

    .accAssignment-w {
        min-width: 150px;
    }

    .editPOItem-w {
        min-width: 100px;
    }

    .sVItemNo-w {
        min-width: 100px;
    }

    .sVPendingQty-w {
        min-width: 110px;
    }

    .sVQty-w {
        max-width: 70px;
    }

    .sVText-w {
        min-width: 150px;
    }

    .itemCode-w {
        min-width: 150px;
    }

    .editPONo-w {
        min-width: 160px;
    }

    /* .mat-table.font-info .mat-header-cell,
      .mat-table.font-info .mat-cell{
          font-size: 7.5px !important;
      } */
    .po-item-po {
        max-width: 50px !important;
    }

    .material-po {
        max-width: 70px !important;
    }

    .shortText-po {
        min-width: 150px !important;
    }

    .invoicedAmt-po {
        max-width: 90px !important;
    }

    .itemAmt-po {
        max-width: 90px !important;
    }

    .ItemQty-po {
        max-width: 70px !important;
    }

    .recQty-po {
        max-width: 80px !important;
    }

    .penQty-po {
        max-width: 80px !important;
    }

    .unitPrice-po {
        max-width: 70px !important;
    }

    .penAmt-po {
        max-width: 90px !important;
    }

    .po-number-dn {
        max-width: 70px !important;
    }

    .po-item-dn {
        max-width: 60px !important;
    }

    .material-dn {
        max-width: 70px !important;
    }

    .materialNo-dn {
        max-width: 70px !important;
    }

    .shortText-dn {
        min-width: 120px !important;
    }

    .deliveringQty-dn {
        max-width: 70px !important;
    }

    .PoAmt-dn {
        max-width: 80px !important;
    }

    .PoQty-dn {
        max-width: 70px !important;
    }

    .recQty-dn {
        max-width: 70px !important;
    }

    .penQty-dn {
        max-width: 70px !important;
    }

    .unitPrice-dn {
        max-width: 70px !important;
    }

    .totalAmt-dn {
        max-width: 80px !important;
    }

    .dnNo-In {
        min-width: 120px !important;
    }

    .material-In {
        max-width: 80px !important;
    }

    .shortText-In {
        min-width: 120px !important;
    }

    .deliveringQty-In {
        max-width: 80px !important;
    }

    .PoAmt-In {
        max-width: 80px !important;
    }

    .PoQty-In {
        max-width: 70px !important;
    }

    .recQty-In {
        max-width: 70px !important;
    }

    .penQty-In {
        max-width: 70px !important;
    }

    .unitPrice-In {
        max-width: 70px !important;
    }

    .totalAmt-In {
        max-width: 80px !important;
    }

    mat-table.responseTable {
        min-width: 67vw !important;
        width: inherit !important;
    }

    mat-header-cell.response-w,
    mat-cell.response-w {
        min-width: 120px !important;
        max-width: 160px;
    }

    mat-header-cell.message-w,
    mat-cell.message-w {
        min-width: 390px !important;
        max-width: 750px;
    }

    mat-header-cell.action-w,
    mat-cell.action-w {
        max-width: 100px;
    }

    .first-name-w,
    .last-name-w {
        min-width: 120px !important;
        max-width: 140px !important;

    }

    .role-w {
        min-width: 110px !important;
        max-width: 130px !important;
    }

    .contact-w {
        min-width: 110px !important;
        max-width: 140px !important;
    }

    .email-w {
        min-width: 200px !important;
        max-width: 400px !important;
        word-break: break-word;
    }

    /* .status1-w{
        min-width: 90px !important;
        max-width: 110px !important;
    } */
    /* .actions-w{
        min-width: 90px !important;
        max-width: 100px !important;
    } */

    mat-table.roleManagementTable {
        min-width: 68vw !important;
        width: inherit !important;
    }

    .role-rm-w {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .description-rm-w {
        min-width: 200px !important;
        max-width: 700px !important;
    }

    mat-table.ConfigurationTable {
        min-width: 67vw !important;
        width: inherit !important;
    }

    .propKey {
        min-width: 120px !important;
        max-width: 350px !important;
    }

    .propValue {
        min-width: 300px !important;
        max-width: 700px !important;
    }

    mat-table.AuditTable {
        min-width: 64vw !important;
        width: inherit !important;
    }

    .date-w {
        min-width: 90px !important;
        max-width: 110px !important;
    }

    mat-table.vendorListTable {
        min-width: 95vw !important;
        width: inherit !important;
    }

    .status1-user-w {
        min-width: 85px !important;
        max-width: 150px !important;
    }

    .status2-user-w {
        min-width: 85px !important;
        max-width: 100px !important;
    }

    .active-w {
        min-width: 80px !important;
        max-width: 180px !important;
    }

    .city-w {
        min-width: 150px;
        max-width: 650px;
    }

    .country-w {
        min-width: 80px !important;
        max-width: 150px !important;
    }

    .currency-w {
        min-width: 80px !important;
        max-width: 150px !important;
    }

    .vendorGroup-w {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .companyCode-w {
        justify-content: space-around;
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .vendorCode-w {
        min-width: 135px !important;
        max-width: 200px !important;
    }

    .actions1-w {
        max-width: 115px !important;
    }

    mat-table.deliveryNotePendingTable {
        min-width: 130vw !important;
        width: inherit !important;
    }

    mat-table.deliveryNoteHistoryTable {
        min-width: 166vw !important;
        width: inherit !important;
    }

    .materialDocNum-w {
        min-width: 150px !important;
        max-width: 250px !important;
    }

    .materialDocYear-w {
        min-width: 150px !important;
        max-width: 200px !important;
    }

    .sapResponse-w {
        min-width: 150px !important;
        max-width: 300px !important;
    }

    .shipmentNo-w {
        min-width: 160px !important;
        max-width: 200px !important;
    }

    .deliveryRefNo-w {
        min-width: 180px !important;
        max-width: 300px !important;
    }

    .deliveryNoteDate-w {
        min-width: 165px !important;
        max-width: 165px !important;
    }

    .quantity-w {
        min-width: 100px !important;
        max-width: 150px !important;
    }


    .totalAmt-w {
        padding-right: 30px;
        justify-content: end;
        min-width: 120px !important;
        max-width: 180px !important;
    }

    .statusWide-w {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .createdBy-w {
        min-width: 140px !important;
        max-width: 250px !important;
    }


    mat-table.purchaseOrderTable {
        min-width: 219vw !important;
        width: inherit !important;
    }

    mat-table.purchaseOrderHistoryTable {
        min-width: 186vw !important;
        width: inherit !important;
    }

    .companyName-w {
        min-width: 135px !important;
        max-width: 200px !important;
    }

    .poNo-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .poItem-w {
        min-width: 95px !important;
        max-width: 200px !important;
    }

    .plantCode-w {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .plantName-w {
        min-width: 135px !important;
        max-width: 300px !important;
    }

    .materialGroup-w {
        min-width: 135px !important;
        max-width: 200px !important;
    }

    .shortText1-w {
        min-width: 160px !important;
        max-width: 600px !important;
    }

    .poQty-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .receivedQty-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .pendingQty-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .unitPrice-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .itemAmt-w {
        min-width: 135px !important;
        max-width: 250px !important;
    }

    .invoicedAmt-w {
        min-width: 135px !important;
        max-width: 250px !important;
    }

    .totalAmt1-w {
        min-width: 135px !important;
        max-width: 250px !important;
    }

    mat-table.invoiePendingListTable {
        min-width: 116vw !important;
        width: inherit !important;
    }

    mat-table.invoieHistoryListTable {
        min-width: 148vw !important;
        width: inherit !important;
    }

    .invoiceNo-w {
        min-width: 150px !important;
        max-width: 180px !important;
    }

    .invoiceDate-w {
        min-width: 105px !important;
        max-width: 150px !important;
    }

    .invoiceType-w {
        min-width: 100px;
        max-width: 200px;
    }

    .totalQty-w {
        min-width: 100px;
        max-width: 200px;
    }

    .taxType-w {
        min-width: 100px;
        max-width: 200px;
    }

    .vatAmt-w {
        min-width: 100px;
        max-width: 200px;
    }

    .status-w {
        min-width: 100px;
        max-width: 150px;
    }

    mat-table.weighBridgeTable {
        min-width: 210vw !important;
        width: inherit !important;
    }

    mat-table.weighBridgeTableUp {
        min-width: 226vw !important;
        width: inherit !important;
    }

    .weighTicketNo-wb {
        min-width: 195px !important;
        max-width: 250px !important;
    }

    .truckNo-wb {
        min-width: 175px !important;
        max-width: 250px !important;
    }

    .shipmentNo-wb {
        min-width: 160px !important;
        max-width: 250px !important;
    }

    .weightType-wb {
        min-width: 140px !important;
        max-width: 250px !important;
    }

    .poNumber-wb {
        min-width: 200px !important;
        max-width: 300px !important;
    }

    .itemNo-wb {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .docDate-wb {
        min-width: 150px !important;
        max-width: 200px !important;
    }

    .material-wb {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .materialDesc-wb {
        min-width: 200px !important;
        max-width: 400px !important;
    }

    .weightUnit-wb {
        min-width: 80px !important;
        max-width: 125px !important;
    }

    .weightInQty-wb {
        min-width: 170px !important;
        max-width: 250px !important;
    }

    .vendorNetWeight-wb {
        min-width: 175px !important;
        max-width: 250px !important;
    }

    .weightOutQty-wb {
        min-width: 180px !important;
        max-width: 350px !important;
    }

    .materialDoc-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .materialDocYear-wb {
        min-width: 170px !important;
        max-width: 250px !important;
    }

    .supplyPlant-wb {
        min-width: 150px !important;
        max-width: 280px !important;
    }

    .supplyPtorage-wb {
        min-width: 160px !important;
        max-width: 300px !important;
    }

    .recvpoPlant-wb {
        min-width: 180px !important;
        max-width: 300px !important;
    }

    .recvpoStorage-wb {
        min-width: 195px !important;
        max-width: 300px !important;
    }

    .destinationPlant-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .destinationStorage-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .requiredQty-wb {
        min-width: 170px !important;
        max-width: 350px !important;
    }

    .buom-wb {
        min-width: 165px !important;
        max-width: 200px !important;
    }

    .vendor-wb {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .name1-wb {
        min-width: 130px !important;
        max-width: 300px !important;
    }

}

@media (min-width: 1100px) and (max-width: 1199px) {

    mat-table.userManagementTable {
        min-width: 110vw !important;
        width: inherit !important;
    }

    mat-table.vendorUserTable {
        min-width: 100vw !important;
        width: inherit !important;

    }

    mat-icon.expand {
        margin-left: 30px;
    }

    .mat-table {
        width: 120% !important;
    }

    mat-table.deliveryNoteDetailsTable {
        width: 150vw !important;
    }

    mat-table.invoiceGoodsTable {
        width: 140vw !important;
    }

    mat-table.invoiceServiceTable {
        width: 140vw !important;
    }


    mat-header-cell:first-of-type,
    mat-footer-cell:first-of-type {
        padding-left: 20px !important;
    }

    mat-cell:first-of-type,
    mat-header-cell:first-of-type,
    mat-footer-cell:first-of-type {
        padding-left: 20px !important;
    }


    .status1-user-w {
        min-width: 85px !important;
        max-width: 100px !important;
    }

    .status2-user-w {
        min-width: 85px !important;
        max-width: 100px !important;
    }


    .vendorCode-w {
        min-width: 135px !important;
        max-width: 150px !important;
    }

    .companyCode-w {
        justify-content: space-around;
        min-width: 135px;
    }

    .vendorGroup-w {
        min-width: 115px;
    }


    .actions1-w {
        max-width: 115px !important;
    }

    .city-w {
        min-width: 130px;
    }

    .country-w {
        min-width: 95px;
    }

    .active-w {
        min-width: 95px;
    }

    .editPoNo-w {
        min-width: 110px !important;
    }

    .editDNtotalAmt-w {
        max-width: 110px !important;
    }

    .vendorName-w {
        min-width: 140x !important;
        max-width: 300px !important;
        padding-right: 10px;
    }

    .currency-w {
        min-width: 95px;
        max-width: 95px !important;
    }


    .editPoItem-w {
        min-width: 80px !important;
    }

    .editMaterialGroup-w {
        max-width: 135px !important;
    }

    .materialNo-w {
        min-width: 100px !important;
    }

    .editMaterialNo-w {
        min-width: 100px !important;
    }

    .shortText-w {
        min-width: 340px !important;
        padding-right: 10px !important;
    }

    .editShortText-w {
        min-width: 345px !important;
    }

    .editPoQty-w {
        max-width: 80px !important;
    }

    .poAmount-w {
        min-width: 100px;
    }

    .editPoAmount-w {
        max-width: 100px;
    }


    .editDelQty-w {
        min-width: 80px;
    }

    .editReceivedQty-w {
        max-width: 110px !important;
    }

    .editPendingQty-w {
        max-width: 110px !important;
    }

    .editUnitPrice-w {
        max-width: 100px !important;
    }

    .invoicedAmt-w {
        min-width: 125px !important;
    }

    .editCategory-w {
        min-width: 230px !important;
    }

    .editDNnoteNo-w {
        min-width: 210px;
    }

    .editDeliveringQty-w {
        max-width: 115px !important;
    }

    .accAssignment-w {
        min-width: 150px;
    }

    .editPOItem-w {
        min-width: 150px;
    }

    .sVItemNo-w {
        min-width: 100px;
    }

    .sVPendingQty-w {
        min-width: 110px;
    }

    .sVQty-w {
        max-width: 70px;
    }

    .sVText-w {
        min-width: 150px;
    }

    .itemCode-w {
        min-width: 150px;
    }

    .editPONo-w {
        min-width: 160px;
    }

    .mat-table.font-info .mat-header-cell,
    .mat-table.font-info .mat-cell {
        font-size: 9px !important;
    }

    /* .actions-wide{
        min-width: 70px !important;
        padding-right: 45px !important;
    } */

    /* mat-icon.visibility, mat-icon.edit, mat-icon.block{
        font-size: xx-small;
    } */
    .po-item-po {
        max-width: 50px !important;
    }

    .material-po {
        max-width: 70px !important;
    }

    .shortText-po {
        min-width: 150px !important;
    }

    .invoicedAmt-po {
        max-width: 90px !important;
    }

    .itemAmt-po {
        max-width: 90px !important;
    }

    .ItemQty-po {
        max-width: 70px !important;
    }

    .recQty-po {
        max-width: 80px !important;
    }

    .penQty-po {
        max-width: 80px !important;
    }

    .unitPrice-po {
        max-width: 70px !important;
    }

    .penAmt-po {
        max-width: 90px !important;
    }

    .po-number-dn {
        max-width: 80px !important;
    }

    .po-item-dn {
        max-width: 70px !important;
    }

    .material-dn {
        max-width: 80px !important;
    }

    .materialNo-dn {
        max-width: 80px !important;
    }

    .shortText-dn {
        min-width: 130px !important;
    }

    .deliveringQty-dn {
        max-width: 80px !important;
    }

    .PoAmt-dn {
        max-width: 90px !important;
    }

    .PoQty-dn {
        max-width: 80px !important;
    }

    .recQty-dn {
        max-width: 80px !important;
    }

    .penQty-dn {
        max-width: 80px !important;
    }

    .unitPrice-dn {
        max-width: 80px !important;
    }

    .totalAmt-dn {
        max-width: 90px !important;
    }

    .dnNo-In {
        min-width: 130px !important;
    }

    .material-In {
        min-width: 80px !important;
    }

    .shortText-In {
        min-width: 130px !important;
    }

    .deliveringQty-In {
        max-width: 90px !important;
    }

    .PoAmt-In {
        max-width: 90px !important;
    }

    .PoQty-In {
        max-width: 80px !important;
    }

    .recQty-In {
        max-width: 80px !important;
    }

    .penQty-In {
        max-width: 80px !important;
    }

    .unitPrice-In {
        max-width: 80px !important;
    }

    .totalAmt-In {
        max-width: 90px !important;
    }

    mat-table.responseTable {
        min-width: 70vw !important;
        width: inherit !important;
    }

    mat-header-cell.response-w,
    mat-cell.response-w {
        min-width: 120px !important;
        max-width: 170px;
    }

    mat-header-cell.message-w,
    mat-cell.message-w {
        min-width: 390px !important;
        max-width: 850px;
    }

    mat-header-cell.action-w,
    mat-cell.action-w {
        max-width: 100px;
    }

    .first-name-w,
    .last-name-w {
        min-width: 120px !important;
        max-width: 150px !important;

    }

    .role-w {
        min-width: 100px !important;
        max-width: 130px !important;
    }

    .contact-w {
        min-width: 120px !important;
        max-width: 150px !important;
    }

    .email-w {
        min-width: 200px !important;
        max-width: 400px !important;
        word-break: break-word;
    }

    /* .status1-w{
        min-width: 100px !important;
        max-width: 110px !important;
    } */
    /* .actions-w{
        min-width: 100px !important;
        max-width: 120px !important;
    } */

    mat-table.roleManagementTable {
        min-width: 70vw !important;
        width: inherit !important;
    }

    .role-rm-w {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .description-rm-w {
        min-width: 200px !important;
        max-width: 800px !important;
    }

    mat-table.ConfigurationTable {
        min-width: 70vw !important;
        width: inherit !important;
    }

    .propKey {
        min-width: 120px !important;
        max-width: 300px !important;
    }

    .propValue {
        min-width: 300px !important;
        max-width: 800px !important;
    }

    mat-table.AuditTable {
        min-width: 69vw !important;
        width: inherit !important;
    }

    .date-w {
        min-width: 110px !important;
        max-width: 110px !important;
    }

    mat-table.vendorListTable {
        min-width: 86vw !important;
        width: inherit !important;
    }

    .status1-user-w {
        min-width: 85px !important;
        max-width: 150px !important;
    }

    .status2-user-w {
        min-width: 85px !important;
        max-width: 100px !important;
    }

    .active-w {
        min-width: 80px !important;
        max-width: 180px !important;
    }

    .city-w {
        min-width: 150px;
        max-width: 650px;
    }

    .country-w {
        min-width: 80px !important;
        max-width: 150px !important;
    }

    .currency-w {
        min-width: 80px !important;
        max-width: 150px !important;
    }

    .vendorGroup-w {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .companyCode-w {
        justify-content: space-around;
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .vendorCode-w {
        min-width: 135px !important;
        max-width: 200px !important;
    }

    .actions1-w {
        max-width: 115px !important;
    }

    mat-table.deliveryNotePendingTable {
        min-width: 120vw !important;
        width: inherit !important;
    }

    .deliveryNoteHistoryTable {
        min-width: 149vw !important;
        width: inherit !important;
    }

    .materialDocNum-w {
        min-width: 150px !important;
        max-width: 250px !important;
    }

    .materialDocYear-w {
        min-width: 150px !important;
        max-width: 200px !important;
    }

    .sapResponse-w {
        min-width: 150px !important;
        max-width: 300px !important;
    }

    .shipmentNo-w {
        min-width: 160px !important;
        max-width: 200px !important;
    }

    .deliveryRefNo-w {
        min-width: 180px !important;
        max-width: 300px !important;
    }

    .deliveryNoteDate-w {
        min-width: 165px !important;
        max-width: 165px !important;
    }

    .quantity-w {
        min-width: 100px !important;
        max-width: 150px !important;
    }


    .totalAmt-w {
        padding-right: 30px;
        justify-content: end;
        min-width: 120px !important;
        max-width: 180px !important;
    }

    .statusWide-w {
        min-width: 90px !important;
        max-width: 200px !important;
    }

    .createdBy-w {
        min-width: 140px !important;
        max-width: 250px !important;
    }

    mat-table.purchaseOrderTable {
        min-width: 199vw !important;
        width: inherit !important;
    }

    mat-table.purchaseOrderHistoryTable {
        min-width: 168vw !important;
        width: inherit !important;
    }

    .companyName-w {
        min-width: 135px !important;
        max-width: 200px !important;
    }

    .poNo-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .poItem-w {
        min-width: 95px !important;
        max-width: 200px !important;
    }

    .plantCode-w {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .plantName-w {
        min-width: 135px !important;
        max-width: 300px !important;
    }

    .materialGroup-w {
        min-width: 135px !important;
        max-width: 200px !important;
    }

    .shortText1-w {
        min-width: 160px !important;
        max-width: 600px !important;
    }

    .poQty-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .receivedQty-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .pendingQty-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .unitPrice-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .itemAmt-w {
        min-width: 135px !important;
        max-width: 250px !important;
    }

    .invoicedAmt-w {
        min-width: 135px !important;
        max-width: 250px !important;
    }

    .totalAmt1-w {
        min-width: 135px !important;
        max-width: 250px !important;
    }

    .shipmentNo-w {
        min-width: 160px !important;
        max-width: 200px !important;
    }

    .deliveryRefNo-w {
        min-width: 180px !important;
        max-width: 300px !important;
    }

    .deliveryNoteDate-w {
        min-width: 165px !important;
        max-width: 165px !important;
    }

    .quantity-w {
        min-width: 100px !important;
        max-width: 150px !important;
    }


    .totalAmt-w {
        padding-right: 30px;
        justify-content: end;
        min-width: 120px !important;
        max-width: 180px !important;
    }

    mat-table.invoiePendingListTable {
        min-width: 105vw !important;
        width: inherit !important;
    }

    mat-table.invoieHistoryListTable {
        min-width: 134vw !important;
        width: inherit !important;
    }

    .invoiceNo-w {
        min-width: 150px !important;
        max-width: 180px !important;
    }

    .invoiceDate-w {
        min-width: 105px !important;
        max-width: 150px !important;
    }

    .invoiceType-w {
        min-width: 100px;
        max-width: 200px;
    }

    .totalQty-w {
        min-width: 100px;
        max-width: 200px;
    }

    .taxType-w {
        min-width: 100px;
        max-width: 200px;
    }

    .vatAmt-w {
        min-width: 100px;
        max-width: 200px;
    }

    .status-w {
        min-width: 100px;
        max-width: 150px;
    }

    mat-table.weighBridgeTable {
        min-width: 191vw !important;
        width: inherit !important;
    }

    mat-table.weighBridgeTableUp {
        min-width: 206vw !important;
        width: inherit !important;
    }

    .weighTicketNo-wb {
        min-width: 195px !important;
        max-width: 250px !important;
    }

    .truckNo-wb {
        min-width: 175px !important;
        max-width: 250px !important;
    }

    .shipmentNo-wb {
        min-width: 160px !important;
        max-width: 250px !important;
    }

    .weightType-wb {
        min-width: 140px !important;
        max-width: 250px !important;
    }

    .poNumber-wb {
        min-width: 200px !important;
        max-width: 300px !important;
    }

    .itemNo-wb {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .docDate-wb {
        min-width: 150px !important;
        max-width: 200px !important;
    }

    .material-wb {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .materialDesc-wb {
        min-width: 200px !important;
        max-width: 400px !important;
    }

    .weightUnit-wb {
        min-width: 80px !important;
        max-width: 125px !important;
    }

    .weightInQty-wb {
        min-width: 170px !important;
        max-width: 250px !important;
    }

    .vendorNetWeight-wb {
        min-width: 175px !important;
        max-width: 250px !important;
    }

    .weightOutQty-wb {
        min-width: 180px !important;
        max-width: 350px !important;
    }

    .materialDoc-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .materialDocYear-wb {
        min-width: 170px !important;
        max-width: 250px !important;
    }

    .supplyPlant-wb {
        min-width: 150px !important;
        max-width: 280px !important;
    }

    .supplyPtorage-wb {
        min-width: 160px !important;
        max-width: 300px !important;
    }

    .recvpoPlant-wb {
        min-width: 180px !important;
        max-width: 300px !important;
    }

    .recvpoStorage-wb {
        min-width: 195px !important;
        max-width: 300px !important;
    }

    .destinationPlant-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .destinationStorage-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .requiredQty-wb {
        min-width: 170px !important;
        max-width: 350px !important;
    }

    .buom-wb {
        min-width: 165px !important;
        max-width: 200px !important;
    }

    .vendor-wb {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .name1-wb {
        min-width: 130px !important;
        max-width: 300px !important;
    }


}

@media (min-width: 1200px) and (max-width: 1299px) {

    /* .mat-table{
        width: 105% !important;
    } */

    mat-table.userManagementTable {
        min-width: 110vw !important;
        width: inherit !important;
    }

    mat-table.vendorUserTable {
        min-width: 107vw !important;
        width: inherit !important;

    }


    mat-table.deliveryNoteDetailsTable {
        width: 136vw !important;
    }

    mat-table.invoiceServiceTable {
        width: 130vw !important;
    }

    mat-table.invoiceGoodsTable {
        width: 135vw !important;
    }

    .po-item-po {
        max-width: 50px !important;
    }

    .material-po {
        max-width: 100px !important;
    }

    .shortText-po {
        min-width: 200px !important;
    }

    .invoicedAmt-po {
        max-width: 120px !important;
    }

    .itemAmt-po {
        max-width: 130px !important;
    }

    .ItemQty-po {
        max-width: 100px !important;
    }

    .recQty-po {
        max-width: 100px !important;
    }

    .penQty-po {
        max-width: 100px !important;
    }

    .unitPrice-po {
        max-width: 100px !important;
    }

    .penAmt-po {
        max-width: 130px !important;
    }

    .mat-table.font-info .mat-header-cell,
    .mat-table.font-info .mat-cell {
        font-size: 11px !important;
    }

    .po-number-dn {
        max-width: 100px !important;
    }

    .po-item-dn {
        max-width: 90px !important;
    }

    .material-dn {
        max-width: 100px !important;
    }

    .materialNo-dn {
        max-width: 100px !important;
    }

    .shortText-dn {
        min-width: 150px !important;
    }

    .deliveringQty-dn {
        max-width: 130px !important;
    }

    .PoAmt-dn {
        max-width: 130px !important;
    }

    .PoQty-dn {
        max-width: 100px !important;
    }

    .recQty-dn {
        max-width: 100px !important;
    }

    .penQty-dn {
        max-width: 100px !important;
    }

    .unitPrice-dn {
        max-width: 100px !important;
    }

    .totalAmt-dn {
        max-width: 130px !important;
    }

    .dnNo-In {
        min-width: 150px !important;
    }

    .material-In {
        max-width: 100px !important;
    }

    .shortText-In {
        min-width: 170px !important;
    }

    .deliveringQty-In {
        max-width: 130px !important;
    }

    .PoAmt-In {
        max-width: 130px !important;
    }

    .PoQty-In {
        max-width: 100px !important;
    }

    .recQty-In {
        max-width: 100px !important;
    }

    .penQty-In {
        max-width: 100px !important;
    }

    .unitPrice-In {
        max-width: 100px !important;
    }

    .totalAmt-In {
        max-width: 130px !important;
    }



    mat-table.responseTable {
        min-width: 73vw !important;
        width: inherit !important;
    }

    mat-header-cell.response-w,
    mat-cell.response-w {
        min-width: 120px !important;
        max-width: 180px;
    }

    mat-header-cell.message-w,
    mat-cell.message-w {
        min-width: 390px !important;
        max-width: 950px;
    }

    mat-header-cell.action-w,
    mat-cell.action-w {
        max-width: 100px;
    }

    .first-name-w,
    .last-name-w {
        min-width: 140px !important;
        max-width: 180px !important;

    }

    .role-w {
        min-width: 120px !important;
        max-width: 180px !important;
    }

    .contact-w {
        min-width: 120px !important;
        max-width: 180px !important;
    }

    .email-w {
        min-width: 200px !important;
        max-width: 400px !important;
        word-break: break-word;
    }

    /* .status1-w{
        min-width: 100px !important;
        max-width: 150px !important;
    } */
    /* .actions-w{
        min-width: 100px !important;
        max-width: 150px !important;
    } */

    mat-table.roleManagementTable {
        min-width: 73vw !important;
        width: inherit !important;
    }

    .role-rm-w {
        min-width: 150px !important;
        max-width: 250px !important;
    }

    .description-rm-w {
        min-width: 200px !important;
        max-width: 850px !important;
    }

    /* .actions-w{
        max-width: 100px !important;
      } */

    .invoiceDate-w {
        min-width: 105px !important;
        max-width: 105px !important;
    }

    .invoiceType-w {
        min-width: 100px;
        max-width: 100px;
    }

    .shipmentNo-w {
        min-width: 160px !important;
        max-width: 200px;
    }

    .poNo-w {
        min-width: 95px;
        max-width: 150px;
    }

    .deliveryNoteDate-w {
        min-width: 165px !important;
        max-width: 165px;
    }

    .currency-w {
        min-width: 95px;
        max-width: 95px;
    }

    mat-table.ConfigurationTable {
        min-width: 73vw !important;
        width: inherit !important;
    }

    .propKey {
        min-width: 210px !important;
        max-width: 408px !important;
    }

    .propValue {
        min-width: 300px !important;
        max-width: 1000px !important;
    }

    mat-table.AuditTable {
        min-width: 72vw !important;
        width: inherit !important;
    }

    .date-w {
        min-width: 110px !important;
        max-width: 110px !important;
    }

    mat-table.vendorListTable {
        min-width: 80vw !important;
        width: inherit !important;
    }

    .status1-user-w {
        min-width: 85px !important;
        max-width: 150px !important;
    }

    .status2-user-w {
        min-width: 85px !important;
        max-width: 100px !important;
    }

    .active-w {
        min-width: 80px !important;
        max-width: 180px !important;
    }

    .city-w {
        min-width: 150px;
        max-width: 650px;
    }

    .country-w {
        min-width: 80px !important;
        max-width: 150px !important;
    }

    .currency-w {
        min-width: 80px !important;
        max-width: 150px !important;
    }

    .vendorGroup-w {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .companyCode-w {
        justify-content: space-around;
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .vendorCode-w {
        min-width: 135px !important;
        max-width: 200px !important;
        word-break: break-word;
    }

    .actions1-w {
        max-width: 115px !important;
    }

    mat-table.deliveryNotePendingTable {
        min-width: 110vw !important;
        width: inherit !important;
    }

    mat-table.deliveryNoteHistoryTable {
        min-width: 152vw !important;
        width: inherit !important;
    }

    .materialDocNum-w {
        min-width: 150px !important;
        max-width: 250px !important;
    }

    .materialDocYear-w {
        min-width: 150px !important;
        max-width: 200px !important;
    }

    .sapResponse-w {
        min-width: 150px !important;
        max-width: 300px !important;
    }

    .shipmentNo-w {
        min-width: 160px !important;
        max-width: 200px !important;
    }

    .deliveryRefNo-w {
        min-width: 180px !important;
        max-width: 300px !important;
    }

    .deliveryNoteDate-w {
        min-width: 165px !important;
        max-width: 165px !important;
    }

    .quantity-w {
        min-width: 100px !important;
        max-width: 150px !important;
    }


    .totalAmt-w {
        padding-right: 30px;
        justify-content: end;
        min-width: 120px !important;
        max-width: 180px !important;
    }

    .statusWide-w {
        min-width: 150px !important;
        max-width: 200px !important;
    }

    .createdBy-w {
        min-width: 140px !important;
        max-width: 250px !important;
    }

    mat-table.purchaseOrderTable {
        min-width: 183vw !important;
        width: inherit !important;
    }

    mat-table.purchaseOrderHistoryTable {
        min-width: 168vw !important;
        width: inherit !important;
    }

    .companyName-w {
        min-width: 145px !important;
        max-width: 200px !important;
        justify-content: center;
    }

    .poNo-w {
        min-width: 120px !important;
        max-width: 250px !important;
        justify-content: center;
    }

    .poItem-w {
        min-width: 95px !important;
        max-width: 200px !important;
    }

    .plantCode-w {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .plantName-w {
        min-width: 135px !important;
        max-width: 300px !important;
    }

    .materialGroup-w {
        min-width: 135px !important;
        max-width: 200px !important;
    }

    .shortText1-w {
        min-width: 150px !important;
        max-width: 600px !important;
        word-break: break-word;
    }

    .poQty-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .receivedQty-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .pendingQty-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .unitPrice-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .itemAmt-w {
        min-width: 135px !important;
        max-width: 250px !important;
    }

    .invoicedAmt-w {
        min-width: 135px !important;
        max-width: 250px !important;
    }

    .totalAmt1-w {
        min-width: 135px !important;
        max-width: 250px !important;
    }


    mat-table.invoiePendingListTable {
        min-width: 96vw !important;
        width: inherit !important;
    }

    mat-table.invoieHistoryListTable {
        min-width: 118vw !important;
        width: inherit !important;
    }

    .invoiceNo-w {
        min-width: 150px !important;
        max-width: 180px !important;
    }

    .invoiceDate-w {
        min-width: 160px !important;
        max-width: 180px !important;
        word-break: break-word;
    }

    .invoiceType-w {
        min-width: 100px;
        max-width: 200px;
    }

    .totalQty-w {
        min-width: 100px;
        max-width: 200px;
    }

    .taxType-w {
        min-width: 100px;
        max-width: 200px;
    }

    .vatAmt-w {
        min-width: 100px;
        max-width: 200px;
    }

    .status-w {
        min-width: 137px;
        max-width: 150px;
        word-break: break-word;
    }

    mat-table.weighBridgeTable {
        min-width: 175vw !important;
        width: inherit !important;
    }

    mat-table.weighBridgeTable2 {
        min-width: 165vw !important;
        width: inherit !important;
    }

    mat-table.weighBridgeTableUp {
        min-width: 190vw !important;
        width: inherit !important;
    }

    .weighTicketNo-wb {
        min-width: 195px !important;
        max-width: 250px !important;
    }

    .truckNo-wb {
        min-width: 175px !important;
        max-width: 250px !important;
    }

    .shipmentNo-wb {
        min-width: 160px !important;
        max-width: 250px !important;
    }

    .weightType-wb {
        min-width: 140px !important;
        max-width: 250px !important;
    }

    .poNumber-wb {
        min-width: 200px !important;
        max-width: 300px !important;
    }

    .itemNo-wb {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .docDate-wb {
        min-width: 150px !important;
        max-width: 200px !important;
    }

    .material-wb {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .materialDesc-wb {
        min-width: 200px !important;
        max-width: 400px !important;
    }

    .weightUnit-wb {
        min-width: 80px !important;
        max-width: 125px !important;
    }

    .weightInQty-wb {
        min-width: 170px !important;
        max-width: 250px !important;
    }

    .vendorNetWeight-wb {
        min-width: 175px !important;
        max-width: 250px !important;
    }

    .weightOutQty-wb {
        min-width: 180px !important;
        max-width: 350px !important;
    }

    .materialDoc-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .materialDocYear-wb {
        min-width: 170px !important;
        max-width: 250px !important;
    }

    .supplyPlant-wb {
        min-width: 150px !important;
        max-width: 280px !important;
    }

    .supplyPtorage-wb {
        min-width: 160px !important;
        max-width: 300px !important;
    }

    .recvpoPlant-wb {
        min-width: 180px !important;
        max-width: 300px !important;
    }

    .recvpoStorage-wb {
        min-width: 195px !important;
        max-width: 300px !important;
    }

    .destinationPlant-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .destinationStorage-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .requiredQty-wb {
        min-width: 170px !important;
        max-width: 350px !important;
    }

    .buom-wb {
        min-width: 165px !important;
        max-width: 200px !important;
    }

    .vendor-wb {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .name1-wb {
        min-width: 130px !important;
        max-width: 300px !important;
    }

    .editShortText-w {
        min-width: 275px !important;
    }

}

@media (min-width: 1300px)and (max-width: 1399px) {


    /* working_Code */


    mat-table.deliveryNoteDetailsTable {
        width: 126vw !important;
    }

    mat-table.invoiceServiceTable {
        width: 120vw !important;
    }

    mat-table.invoiceGoodsTable {
        width: 112vw !important;
    }

    mat-table.editInvoiceGoodsTable {
        width: 112vw !important;
    }


    mat-table.userManagementTable {
        min-width: 100vw !important;
        width: inherit !important;
    }

    mat-table.vendorUserTable {
        min-width: 98vw !important;
        width: inherit !important;

    }

    .editDNnoteNo-w {
        min-width: 245px;
    }

    .editDeliveringQty-w {
        min-width: 115px !important;
    }

    .editPONo-w {
        min-width: 160px;
    }

    .editShortText-w {
        min-width: 275px !important;
    }

    .accAssignment-w {
        min-width: 150px;
    }

    .editPOItem-w {
        min-width: 150px;
    }

    .sVItemNo-w {
        min-width: 100px;
    }

    .sVPendingQty-w {
        min-width: 110px;
    }

    .sVQty-w {
        min-width: 100px;
    }

    .sVText-w {
        min-width: 150px;
    }

    .itemCode-w {
        min-width: 150px;
    }

    .po-item-po {
        max-width: 80px !important;
    }

    .invoiceDate-w {
        min-width: 105px !important;
        max-width: 105px !important;
    }

    .invoiceType-w {
        min-width: 100px;
        max-width: 100px;
    }

    .material-po {
        max-width: 100px !important;
    }

    .shortText-po {
        min-width: 250px !important;
    }

    .invoicedAmt-po {
        max-width: 130px !important;
    }

    .itemAmt-po {
        max-width: 130px !important;
    }

    .ItemQty-po {
        max-width: 100px !important;
    }

    .recQty-po {
        max-width: 100px !important;
    }

    .penQty-po {
        max-width: 100px !important;
    }

    .unitPrice-po {
        max-width: 100px !important;
    }

    .penAmt-po {
        max-width: 130px !important;
    }

    .mat-table.font-info .mat-header-cell,
    .mat-table.font-info .mat-cell {
        font-size: 13px !important;
    }

    .po-number-dn {
        max-width: 100px !important;
    }

    .po-item-dn {
        max-width: 90px !important;
    }

    .material-dn {
        max-width: 100px !important;
    }

    .materialNo-dn {
        max-width: 100px !important;
    }

    .shortText-dn {
        min-width: 170px !important;
    }

    .deliveringQty-dn {
        max-width: 130px !important;
    }

    .PoAmt-dn {
        max-width: 130px !important;
    }

    .PoQty-dn {
        max-width: 100px !important;
    }

    .recQty-dn {
        max-width: 100px !important;
    }

    .penQty-dn {
        max-width: 100px !important;
    }

    .unitPrice-dn {
        max-width: 100px !important;
    }

    .totalAmt-dn {
        max-width: 130px !important;
    }

    .dnNo-In {
        min-width: 150px !important;
    }

    .material-In {
        max-width: 100px !important;
    }

    .shortText-In {
        min-width: 174px !important;
    }

    .deliveringQty-In {
        max-width: 130px !important;
    }

    .PoAmt-In {
        max-width: 130px !important;
    }

    .PoQty-In {
        max-width: 100px !important;
    }

    .recQty-In {
        max-width: 100px !important;
    }

    .penQty-In {
        max-width: 100px !important;
    }

    .unitPrice-In {
        max-width: 100px !important;
    }

    .totalAmt-In {
        max-width: 130px !important;
    }

    mat-table.responseTable {
        min-width: 75vw !important;
        width: inherit !important;
    }

    mat-header-cell.response-w,
    mat-cell.response-w {
        min-width: 120px !important;
        max-width: 180px;
    }

    mat-header-cell.message-w,
    mat-cell.message-w {
        min-width: 390px !important;
        max-width: 1200px;
    }

    mat-header-cell.action-w,
    mat-cell.action-w {
        max-width: 100px;
    }

    .first-name-w,
    .last-name-w {
        min-width: 150px !important;
        max-width: 220px !important;

    }

    .role-w {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .contact-w {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .email-w {
        min-width: 200px !important;
        max-width: 400px !important;
        word-break: break-word;
    }

    /* .status1-w{
        min-width: 100px !important;
        max-width: 200px !important;
    } */
    /* .actions-w{
        min-width: 100px !important;
        max-width: 150px !important;
    } */

    mat-table.roleManagementTable {
        min-width: 75vw !important;
        width: inherit !important;
    }

    .role-rm-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .description-rm-w {
        min-width: 300px !important;
        max-width: 1250px !important;
    }

    .poNo-w {
        min-width: 95px;
    }

    mat-table.ConfigurationTable {
        min-width: 75vw !important;
        width: inherit !important;
    }

    .propKey {
        min-width: 120px !important;
        max-width: 300px !important;
    }

    .propValue {
        min-width: 300px !important;
        max-width: 1300px !important;
    }

    mat-table.AuditTable {
        min-width: 74vw !important;
        width: inherit !important;
    }

    .date-w {
        min-width: 110px !important;
        max-width: 110px !important;
    }

    mat-table.vendorListTable {
        min-width: 75vw !important;
        width: inherit !important;
    }

    .status1-user-w {
        min-width: 85px !important;
        max-width: 150px !important;
    }

    .status2-user-w {
        min-width: 85px !important;
        max-width: 100px !important;
    }

    .active-w {
        min-width: 80px !important;
        max-width: 180px !important;
    }

    .city-w {
        min-width: 150px;
        max-width: 650px;
    }

    .country-w {
        min-width: 80px !important;
        max-width: 150px !important;
    }

    .currency-w {
        min-width: 80px !important;
        max-width: 150px !important;
    }

    .vendorGroup-w {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .companyCode-w {
        justify-content: space-around;
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .vendorCode-w {
        min-width: 110px !important;
        max-width: 140px !important;
    }

    .actions1-w {
        max-width: 115px !important;
    }

    mat-table.deliveryNotePendingTable {
        min-width: 104vw !important;
        width: inherit !important;
    }

    mat-table.Dpending {
        min-width: 111vw !important;
        width: inherit !important;
    }

    mat-table.Hpending {
        min-width: 130vw !important;
        width: inherit !important;
    }

    mat-table.deliveryNoteHistoryTable {
        min-width: 140vw !important;
        width: inherit !important;
    }

    .materialDocNum-w {
        min-width: 140px !important;
        max-width: 250px !important;
    }

    .materialDocYear-w {
        min-width: 150px !important;
        max-width: 200px !important;
    }

    .sapResponse-w {
        min-width: 150px !important;
        max-width: 300px !important;
    }

    .shipmentNo-w {
        min-width: 160px !important;
        max-width: 250px !important;
    }

    .deliveryRefNo-w {
        min-width: 200px !important;
        max-width: 390px !important;
    }

    .deliveryNoteDate-w {
        min-width: 174px !important;
        max-width: 180px !important;
    }

    .quantity-w {
        justify-content: end;
        padding-right: 10px;
        min-width: 100px !important;
        max-width: 250px !important;
    }


    .totalAmt-w {
        padding-right: 30px;
        justify-content: end;
        min-width: 120px !important;
        max-width: 150px !important;
    }

    .statusWide-w {
        min-width: 150px !important;
        max-width: 250px !important;
    }

    .createdBy-w {
        min-width: 140px !important;
        max-width: 250px !important;
    }

    mat-table.purchaseOrderTable {
        min-width: 156vw !important;
        width: inherit !important;
    }

    mat-table.purchaseOrderHistoryTable {
        min-width: 146vw !important;
        width: inherit !important;
    }

    .companyName-w {
        min-width: 142px !important;
        max-width: 200px !important;
    }

    .poNo-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .poItem-w {
        min-width: 95px !important;
        max-width: 200px !important;
    }

    .plantCode-w {
        min-width: 122px !important;
        max-width: 200px !important;
    }

    .plantName-w {
        min-width: 135px !important;
        max-width: 300px !important;
    }

    .materialGroup-w {
        min-width: 135px !important;
        max-width: 200px !important;
    }

    .shortText1-w {
        min-width: 172px !important;
        max-width: 600px !important;
        word-break: break-word;
    }

    .poQty-w {
        justify-content: end;
        padding-right: 30px;
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .receivedQty-w {
        justify-content: end;
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .pendingQty-w {
        justify-content: end;
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .unitPrice-w {
        justify-content: end;
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .itemAmt-w {
        justify-content: end;
        padding-right: 20px;
        min-width: 135px !important;
        max-width: 250px !important;
    }

    .invoicedAmt-w {
        justify-content: end;
        padding-right: 20px;
        min-width: 135px !important;
        max-width: 250px !important;
    }

    .totalAmt1-w {
        justify-content: end;
        padding-right: 20px;
        min-width: 135px !important;
        max-width: 250px !important;
    }

    mat-table.invoiePendingListTable {
        min-width: 89vw !important;
        width: inherit !important;
    }

    mat-table.invoieHistoryListTable {
        min-width: 108vw !important;
        width: inherit !important;
    }

    .invoiceNo-w {
        min-width: 150px !important;
        max-width: 180px !important;
    }

    .invoiceDate-w {
        word-break: break-word;
        min-width: 160px !important;
        max-width: 160px !important;
    }

    .invoiceType-w {
        min-width: 100px;
        max-width: 200px;
    }

    .totalQty-w {
        min-width: 100px;
        max-width: 200px;
    }

    .taxType-w {
        min-width: 100px;
        max-width: 200px;
    }

    .vatAmt-w {
        justify-content: end;
        padding-right: 25px;
        min-width: 100px;
        max-width: 200px;
    }

    .status-w {
        min-width: 112px;
        max-width: 150px;
    }

    mat-table.weighBridgeTable {
        min-width: 155vw !important;
        width: inherit !important;
    }

    mat-table.weighBridgeTableUp {
        min-width: 175vw !important;
        width: inherit !important;
    }

    .weighTicketNo-wb {
        min-width: 195px !important;
        max-width: 250px !important;
    }

    .truckNo-wb {
        min-width: 175px !important;
        max-width: 250px !important;
    }

    .shipmentNo-wb {
        min-width: 160px !important;
        max-width: 250px !important;
    }

    .weightType-wb {
        min-width: 140px !important;
        max-width: 250px !important;
    }

    .poNumber-wb {
        min-width: 200px !important;
        max-width: 300px !important;
    }

    .itemNo-wb {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .docDate-wb {
        min-width: 150px !important;
        max-width: 200px !important;
    }

    .material-wb {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .materialDesc-wb {
        min-width: 200px !important;
        max-width: 400px !important;
    }

    .weightUnit-wb {
        min-width: 80px !important;
        max-width: 125px !important;
    }

    .weightInQty-wb {
        min-width: 170px !important;
        max-width: 250px !important;
    }

    .vendorNetWeight-wb {
        min-width: 175px !important;
        max-width: 250px !important;
    }

    .weightOutQty-wb {
        min-width: 180px !important;
        max-width: 350px !important;
    }

    .materialDoc-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .materialDocYear-wb {
        min-width: 170px !important;
        max-width: 250px !important;
    }

    .supplyPlant-wb {
        min-width: 150px !important;
        max-width: 280px !important;
    }

    .supplyPtorage-wb {
        min-width: 160px !important;
        max-width: 300px !important;
    }

    .recvpoPlant-wb {
        min-width: 180px !important;
        max-width: 300px !important;
    }

    .recvpoStorage-wb {
        min-width: 195px !important;
        max-width: 300px !important;
    }

    .destinationPlant-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .destinationStorage-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .requiredQty-wb {
        min-width: 170px !important;
        max-width: 350px !important;
    }

    .buom-wb {
        min-width: 165px !important;
        max-width: 200px !important;
    }

    .vendor-wb {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .name1-wb {
        min-width: 130px !important;
        max-width: 300px !important;
    }

    .editDNtotalAmt-w {
        min-width: 120px !important;
    }
}

@media (min-width: 1400px) {

    mat-table.deliveryNoteDetailsTable {
        width: 126vw !important;
    }

    mat-table.invoiceServiceTable {
        width: 120vw !important;
    }

    mat-table.invoiceGoodsTable {
        width: 112vw !important;
    }

    mat-table.editInvoiceGoodsTable {
        width: 112vw !important;
    }


    mat-table.userManagementTable {
        min-width: 96vw !important;
        width: inherit !important;
    }

    mat-table.vendorUserTable {
        min-width: 104vw !important;
        width: inherit !important;

    }

    .editDNnoteNo-w {
        min-width: 245px;
    }

    .editDeliveringQty-w {
        max-width: 120px !important;
    }

    .editPONo-w {
        min-width: 160px;
    }

    .editShortText-w {
        min-width: 275px !important;
    }

    .accAssignment-w {
        min-width: 150px;
    }

    .editPOItem-w {
        min-width: 150px;
    }

    .sVItemNo-w {
        min-width: 100px;
    }

    .sVPendingQty-w {
        min-width: 110px;
    }

    .sVQty-w {
        min-width: 100px;
    }

    .sVText-w {
        min-width: 150px;
    }

    .itemCode-w {
        min-width: 150px;
    }

    .po-item-po {
        max-width: 80px !important;
    }

    .material-po {
        max-width: 100px !important;
    }

    .shortText-po {
        min-width: 250px !important;
    }

    .invoicedAmt-po {
        max-width: 130px !important;
    }

    .itemAmt-po {
        max-width: 130px !important;
    }

    .ItemQty-po {
        max-width: 100px !important;
    }

    .recQty-po {
        max-width: 100px !important;
    }

    .penQty-po {
        max-width: 100px !important;
    }

    .unitPrice-po {
        max-width: 100px !important;
    }

    .penAmt-po {
        max-width: 130px !important;
    }

    .mat-table.font-info .mat-header-cell,
    .mat-table.font-info .mat-cell {
        font-size: 13px !important;
    }

    .po-number-dn {
        max-width: 100px !important;
    }

    .po-item-dn {
        max-width: 90px !important;
    }

    .material-dn {
        max-width: 100px !important;
    }

    .materialNo-dn {
        max-width: 100px !important;
    }

    .shortText-dn {
        min-width: 170px !important;
    }

    .deliveringQty-dn {
        max-width: 130px !important;
    }

    .PoAmt-dn {
        max-width: 130px !important;
    }

    .PoQty-dn {
        max-width: 100px !important;
    }

    .recQty-dn {
        max-width: 100px !important;
    }

    .penQty-dn {
        max-width: 100px !important;
    }

    .unitPrice-dn {
        max-width: 100px !important;
    }

    .totalAmt-dn {
        max-width: 130px !important;
    }

    .dnNo-In {
        min-width: 140px !important;
    }

    .material-In {
        max-width: 100px !important;
    }

    .shortText-In {
        min-width: 220px !important;
        max-width: 220px !important
    }

    .deliveringQty-In {
        max-width: 130px !important;
    }

    .PoAmt-In {
        max-width: 130px !important;
    }

    .PoQty-In {
        max-width: 100px !important;
    }

    .recQty-In {
        max-width: 100px !important;
    }

    .penQty-In {
        max-width: 100px !important;
    }

    .unitPrice-In {
        max-width: 100px !important;
    }

    .totalAmt-In {
        max-width: 87px !important;
    }

    mat-table.responseTable {
        min-width: 75vw !important;
        width: inherit !important;
    }

    mat-header-cell.response-w,
    mat-cell.response-w {
        min-width: 130px !important;
        max-width: 250px;
    }

    mat-header-cell.message-w,
    mat-cell.message-w {
        min-width: 390px !important;
        max-width: 2100px;
    }

    mat-header-cell.action-w,
    mat-cell.action-w {
        max-width: 100px;
    }

    .first-name-w,
    .last-name-w {
        min-width: 160px !important;
        max-width: 220px !important;

    }

    .role-w {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .contact-w {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .email-w {
        min-width: 200px !important;
        max-width: 1500px !important;
        word-break: break-word;
    }

    mat-table.roleManagementTable {
        min-width: 77vw !important;
        width: inherit !important;
    }

    .role-rm-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .description-rm-w {
        min-width: 200px !important;
        max-width: 2075px !important;
    }

    mat-table.ConfigurationTable {
        min-width: 75vw !important;
        width: inherit !important;
    }

    .propKey {
        min-width: 120px !important;
        max-width: 300px !important;
    }

    .propValue {
        min-width: 300px !important;
        max-width: 2000px !important;
    }

    mat-table.AuditTable {
        min-width: 75vw !important;
        width: inherit !important;
    }

    .date-w {
        min-width: 110px !important;
        max-width: 110px !important;
    }

    mat-table.vendorListTable {
        min-width: 75vw !important;
        width: inherit !important;
    }

    .status1-user-w {
        min-width: 85px !important;
        max-width: 150px !important;
    }

    .status2-user-w {
        min-width: 85px !important;
        max-width: 100px !important;
    }

    .active-w {
        min-width: 80px !important;
        max-width: 180px !important;
    }

    .city-w {
        min-width: 150px;
        max-width: 650px;
    }

    .country-w {
        min-width: 80px !important;
        max-width: 150px !important;
    }

    .currency-w {
        min-width: 80px !important;
        max-width: 110px !important;
    }

    .vendorGroup-w {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .companyCode-w {
        justify-content: space-around;
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .vendorCode-w {
        min-width: 135px !important;
        max-width: 200px !important;
    }

    .actions1-w {
        max-width: 130px !important;
        min-width: 130px !important;
    }

    mat-table.deliveryNotePendingTable {
        min-width: 113vw !important;
        width: inherit !important;
    }

    mat-table.deliveryNoteHistoryTable {
        min-width: 125vw !important;
        width: inherit !important;
    }

    .materialDocNum-w {
        min-width: 150px !important;
        max-width: 210px !important;
    }

    .materialDocYear-w {
        min-width: 150px !important;
        max-width: 200px !important;
    }

    .sapResponse-w {
        min-width: 150px !important;
        max-width: 300px !important;
    }

    .shipmentNo-w {
        min-width: 160px !important;
        max-width: 250px !important;
    }

    .deliveryRefNo-w {
        min-width: 180px !important;
        max-width: 390px !important;
    }

    .deliveryNoteDate-w {
        min-width: 165px !important;
        max-width: 200px !important;
    }

    .quantity-w {
        min-width: 100px !important;
        max-width: 250px !important;
    }


    .totalAmt-w {
        padding-right: 30px;
        justify-content: end;
        min-width: 120px !important;
        max-width: 130px !important;
    }

    .statusWide-w {
        min-width: 150px !important;
        max-width: 250px !important;
    }

    .createdBy-w {
        min-width: 140px !important;
        max-width: 250px !important;
    }

    mat-table.purchaseOrderTable {
        min-width: 144vw !important;
        width: inherit !important;
    }

    mat-table.purchaseOrderHistoryTable {
        min-width: 132vw !important;
        width: inherit !important;
    }

    .companyName-w {
        min-width: 100px !important;
        max-width: 145px !important;
    }

    .poNo-w {
        min-width: 120px !important;
        max-width: 130px !important;
    }

    .poItem-w {
        min-width: 95px !important;
        max-width: 200px !important;
    }

    .plantCode-w {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .plantName-w {
        min-width: 135px !important;
        max-width: 300px !important;
    }

    .materialGroup-w {
        min-width: 135px !important;
        max-width: 200px !important;
    }

    .shortText1-w {
        min-width: 172px !important;
        max-width: 600px !important;
        word-break: break-word;
    }

    .poQty-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .receivedQty-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .pendingQty-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .unitPrice-w {
        min-width: 120px !important;
        max-width: 250px !important;
    }

    .itemAmt-w {
        min-width: 135px !important;
        max-width: 250px !important;
    }

    .invoicedAmt-w {
        min-width: 135px !important;
        max-width: 250px !important;
    }

    .totalAmt1-w {
        min-width: 135px !important;
        max-width: 250px !important;
    }

    mat-table.invoiePendingListTable {
        min-width: 83vw !important;
        width: inherit !important;
    }

    mat-table.invoieHistoryListTable {
        min-width: 103vw !important;
        width: inherit !important;
    }

    .invoiceNo-w {
        min-width: 150px !important;
        max-width: 180px !important;
    }

    .invoiceDate-w {
        min-width: 150px !important;
        max-width: 150px !important;
    }

    .invoiceType-w {
        min-width: 100px;
        max-width: 200px;
    }

    .totalQty-w {
        min-width: 100px;
        max-width: 200px;
    }

    .taxType-w {
        min-width: 100px;
        max-width: 105px;
    }

    .vatAmt-w {
        min-width: 100px;
        max-width: 250px;
    }

    .status-w {
        min-width: 100px;
        max-width: 200px;
    }

    mat-table.weighBridgeTable {
        min-width: 150vw !important;
        width: inherit !important;
    }

    mat-table.weighBridgeTableUp {
        min-width: 166vw !important;
        width: inherit !important;
    }

    .weighTicketNo-wb {
        min-width: 195px !important;
        max-width: 250px !important;
    }

    .truckNo-wb {
        min-width: 175px !important;
        max-width: 250px !important;
    }

    .shipmentNo-wb {
        min-width: 160px !important;
        max-width: 250px !important;
    }

    .weightType-wb {
        min-width: 140px !important;
        max-width: 250px !important;
    }

    .poNumber-wb {
        min-width: 200px !important;
        max-width: 300px !important;
    }

    .itemNo-wb {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .docDate-wb {
        min-width: 150px !important;
        max-width: 200px !important;
    }

    .material-wb {
        min-width: 100px !important;
        max-width: 200px !important;
    }

    .materialDesc-wb {
        min-width: 200px !important;
        max-width: 400px !important;
    }

    .weightUnit-wb {
        min-width: 80px !important;
        max-width: 125px !important;
    }

    .weightInQty-wb {
        min-width: 170px !important;
        max-width: 250px !important;
    }

    .vendorNetWeight-wb {
        min-width: 175px !important;
        max-width: 250px !important;
    }

    .weightOutQty-wb {
        min-width: 180px !important;
        max-width: 350px !important;
    }

    .materialDoc-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .materialDocYear-wb {
        min-width: 170px !important;
        max-width: 250px !important;
    }

    .supplyPlant-wb {
        min-width: 150px !important;
        max-width: 280px !important;
    }

    .supplyPtorage-wb {
        min-width: 160px !important;
        max-width: 300px !important;
    }

    .recvpoPlant-wb {
        min-width: 180px !important;
        max-width: 300px !important;
    }

    .recvpoStorage-wb {
        min-width: 195px !important;
        max-width: 300px !important;
    }

    .destinationPlant-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .destinationStorage-wb {
        min-width: 170px !important;
        max-width: 300px !important;
    }

    .requiredQty-wb {
        min-width: 170px !important;
        max-width: 350px !important;
    }

    .buom-wb {
        min-width: 165px !important;
        max-width: 200px !important;
    }

    .vendor-wb {
        min-width: 120px !important;
        max-width: 200px !important;
    }

    .name1-wb {
        min-width: 130px !important;
        max-width: 300px !important;
    }


}


/*Table Styling for Different screen width*/

/*Spinner Starts Here*/

.mat-progress-spinner.mat-progress-spinner-indeterminate-animation[mode=indeterminate] circle {
    stroke: red;
    stroke-width: 5% !important;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.overlay {
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.286);
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
}

/*Spinner End Here*/

/*Input Validations Styling Starts Here*/
.mat-form-field.validations.ng-touched .mat-form-field-outline,
.mat-form-field.validations.ng-touched .mat-form-field-label {
    color: red !important;
}

.mat-form-field.validations.ng-valid .mat-form-field-outline,
.mat-form-field.validations.ng-valid .mat-form-field-label {
    color: black !important;
}

/*Input Validations Styling Ends Here*/
.padding {
    padding: 0px 0px 0px 15px;
}

.mat-select-panel:not([class*=mat-elevation-z]) {
    margin-top: 45px;
    width: 165px;
}

.multiselect-dropdown .dropdown-btn {
    display: inline-block;
    border: 1.5px solid #000 !important;
    width: 100%;
    padding: 12px 12px !important;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.52857143;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    height: 47px;
    border-radius: 4px;
    overflow: hidden;
}

.multiselect-dropdown .dropdown-btn:hover {
    border: 2px solid #67757c !important;
}

.multiselect-dropdown .dropdown-btn.ng-touched {
    border: 2px solid #67757c !important;
}

.multiselect-dropdown .dropdown-btn:focus {
    border: 2px solid #67757c !important;

}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
    top: 10% !important;
    line-height: 25px !important;
    transform: rotate(0deg) !important;
}


.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
    top: 50% !important;
    border-width: 5px 5px 0 !important;
}

/*Landing Page*/

.mat-toolbar {
    background: #ffffff !important;
}

.logo {
    height: 60px;
    width: 150px;
}

.landingLogo {
    display: flex;
    justify-content: start;
}

.toolbarMenus {
    display: flex;
    justify-content: center;
}

.landingLoginButton {
    display: flex;
    justify-content: end;
}

.mat-icon.home-icon {
    margin-right: 25px !important;
    position: relative;
    top: 7px;
    ;
}

button.btnAboutUs {
    margin-right: 55px;
}

button.btnMedia {
    margin-right: 28px;
}

button.btnProductNServices {
    margin-right: 143px;
}

img.contentBg-logo {
    height: 55vh;
    width: 100%;
}

@media (min-width: 320px) {
    img.contentBg-logo {
        height: 10%;
        width: 100%;
    }

    .main {
        height: auto;
    }
}

@media (max-width:768px) {
    .mat-toolbar.toolbar {
        height: auto !important;
        padding-bottom: 15px;
    }

    .page-group {
        display: flex;
        justify-content: end;
    }

    .landingLogo,
    .mat-toolbar.toolbar,
    .toolbarMenus,
    .landingLoginButton {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        align-self: center;
        width: 100% !important;
    }



    .mat-icon.home-icon {
        padding: 10px 0px 40px 0px !important;
    }

    button.btnAboutUs,
    button.btnMedia,
    button.btnProductNServices,
    button.btnCarreers {
        padding-bottom: 15px !important;
    }

    button.btnCarreers {
        position: relative;
        right: 24px;
        padding-bottom: 45px !important;
    }

    .landingLogin {
        position: relative;
        right: 12px;
    }

    .delete-row-parent {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }

    .editDNnoteNo-w {
        height: max-content !important;
    }

    /* mat-table .mat-cell{
    height: max-content !important;

} */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* .multiselect-dropdown .dropdown-btn {
    border: none !important;
    margin-right: 4px;
    background: #fff !important;
    padding: 0 5px;
    color: #000 !important;
    border-radius: 2px;
    float: left;
    max-width: 100% !important;
    white-space: nowrap;
    display: grid;
} */

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    border: 1px solid #ffffff !important;
    margin-right: 4px;
    background: #ffffff !important;
    padding: 0 5px;
    color: #000 !important;
    border-radius: 2px;
    float: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    /* min-width: 200px !important; */
    max-width: 100% !important;
}

/* .multiselect-dropdown .dropdown-btn {
    display: inline-block;
    border: 1.5px solid #67757c !important;
    width: 100%;
    margin-top: 4px;
    padding: 6px 12px;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.52857143;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 4px;
} */



/*Landing Page*/


/*Info Pages ---------------------------->*/
div.pageHeading p {
    text-align: center;
    margin: 0px 0px;
}

div.pageHeading .companyName,
h5.pageSubHeading {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
}

h6.supportDocHeading {
    text-align: start;
    font-weight: 500;
    font-size: 16px;
    margin: 0px;
}

ul.listStyle {
    list-style: none;
    padding-inline-start: 0px;
}

ul.listStyle a {
    text-decoration: underline;
    color: #0088CC;
}

/*Info Pages <............................*/

.page-body {
    padding: 0px 10px 30px 5px;
}

.multiselect-dropdown .disabled>span {
    background-color: #ffffff !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item a {
    text-decoration: none;
    display: none !important;
}

.dropdown-list .filter-textbox input {
    border: 0;
    width: 100%;
    padding: 0 0 0 26px;
    height: 30px;
    font-size: inherit;
}

.gLAcct-costCenter-Input .multiselect-dropdown .dropdown-list {
    position: absolute !important;
    /* top: 90% !important; */
    width: 25% !important;
    overflow: hidden;
}

.gLAcct-costCenter-Input .multiselect-dropdown {
    position: static !important;
}

/* @media print{
    mat-row{
        border: 2px solid black !important;
    }

    mat-header-row{
        border-top: 2px solid black !important;
        border-left: 2px solid black !important;
        border-right: 2px solid black !important;
    }
    
    mat-cell,
    mat-header-cell{
        border-right: 2px solid black;
    }

    .mat-header-cell,
    .mat-cell{
        justify-content: center !important;
    }
} */

.orderDetails {
    display: flex;
    justify-content: center;
}

.orderDetails p {
    margin: 0px;
    font-size: x-small;
    font-weight: 600;
}

.orderDetails a {
    color: rgba(0, 0, 255, 0.795);

}

.mat-tooltip {
    font-size: 14px !important;
    font-weight: 400;
    white-space: pre-wrap;
    color: rgb(14, 14, 14) !important;
    background-color: rgb(255, 255, 255) !important;
    border: 1px solid gray;
    max-width: 300px !important;
}

.swal2-styled.swal2-confirm {
    background-color: #ed1c24 !important;
    color: #fff;
    font-size: 14px;
    width: 35px !important;
    height: 30px !important;
    position: relative;
    top: -10px;
    padding: 5px;
}

.swal2-title {
    padding: 10px 10px 0px 10px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 25px !important;
}

.swal2-html-container {
    margin: 0px !important;
    padding: 10px 10px 0px 10px !important;
    font-weight: 500 !important;
    line-height: 25px !important;
    font-size: 14px !important;
}

.swal2-icon.swal2-warning.swal2-icon-show,
.swal2-icon.swal2-success.swal2-icon-show,
.swal2-icon.swal2-info.swal2-icon-show {
    font-size: xx-small !important;
}


.swal2-icon.swal2-success.swal2-icon-success-line {
    background-color: rgb(252, 5, 5) !important;
}

.swal2-icon.swal2-error.swal2-icon-show {
    font-size: xx-small !important;
    color: #ed1c24 !important;
}

.swal2-container.swal2-center>.swal2-popup {
    min-width: 200px !important;
    max-width: fit-content !important;
}

.swal2-popup {
    padding: 0px !important;
}

.swal2-icon.swal2-warning {
    border-color: #f08426 !important;
    color: #f08426 !important;
}

.swal2-icon.swal2-error {
    border-color: #ed1c24 !important;
    color: #ed1c24 !important;
}

.swal2-icon.swal2-info {
    border-color: #0098ca;
    color: #0098ca;
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
    background-color: #ed1c24 !important;
}

.swal2-icon.swal2-success {
    border-color: #47c200 !important;
}

.swal2-success-line-tip,
.swal2-success-line-long {
    background-color: #47c200 !important;
}

.dropdown-list {
    overflow: hidden !important;
}